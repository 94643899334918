import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// utils
import { getClassNames, modeColors } from "@utils/helpers";

// Context
import { DemoGuidePopoverContext } from "@context/demoGuidePopover";

// Redux
import {
  getProjects,
  // deleteProject,
  setActiveProject,
  setProjectType,
  getProjectById,
} from "@redux/features/projects";
import { IRootState } from "@/redux/store";

// Components
import { Button } from "@components/button";
// import CustomPopover from "@components/customPopover";
import DemoGuidePopover from "@components/DemoGuidePopover";
// import { ProjectModeToggle } from "@components/ProjectStateToggle";
import Topbar from "@components/topbar";

// Assets
// import Ellipsis from "@icons/Ellipsis";
import { ReactComponent as RRFManageIcon } from "@icons/payment_personalization.svg";
import { ReactComponent as PaymentPersonalizationIcon } from "@icons/payment_personalization.svg";
import { ReactComponent as ScoreIcon } from "@icons/score.svg";
import { ReactComponent as IdentityIcon } from "@icons/tamperproof.svg";
import { ReactComponent as ReturnsOrchestratorIcon } from "@icons/returns_ochestrator_sm.svg";

const ManageSolutions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { projects } = useSelector((state: IRootState) => state.projectSlice);
  const { setPopUp7, setPopUp8, popUp7, popUp8 } = React.useContext(
    DemoGuidePopoverContext
  );

  useEffect(() => {
    dispatch(getProjects() as any);
  }, [dispatch]);

  const [env, setEnv] = useState("SANDBOX");
  const projectIcons = {
    CHECKOUT_AND_REFUND: <RRFManageIcon className="max-h-[18px]" />,
    PAYMENT_PERSONALIZATION: (
      <PaymentPersonalizationIcon className="max-h-[18px]" />
    ),
    CHECKOUT_AND_REFUND_MANAGER: (
      <PaymentPersonalizationIcon className="max-h-[18px]" />
    ),
    SHOPPER_TRUST_INDEX: <ScoreIcon className="max-h-[18px] ml-2" />,
    IDENTITY_AND_AUTHENTICATION: <IdentityIcon className="max-h-[18px] ml-2" />,
    RETURNS_ORCHESTRATOR: (
      <ReturnsOrchestratorIcon className="max-h-[18px] ml-2" />
    ),
  };
  const projectTypeNames = {
    CHECKOUT_AND_REFUND: "Checkout And Refund",
    CHECKOUT_AND_REFUND_MANAGER: "Payment Personalization",
    SHOPPER_TRUST_INDEX: "Shopper Trust Index",
    RETURNS_ORCHESTRATOR: "Refunds Orchestrator",
    IDENTITY_AND_AUTHENTICATION: "Identity and Authentication",
    PAYMENT_PERSONALIZATION: "Payment Personalization",
  };

  // const handleDelete = async (id: string) => {
  //   await dispatch(deleteProject({ id }) as any)
  //     .unwrap()
  //     .then(() => {
  //       dispatch(getProjects() as any);
  //     })
  //     .catch((err: any) => console.log(err));
  // };

  const useCase = localStorage.getItem("product") || "PAYMENT_PERSONALIZATION";

  return (
    <div className="w-full h-full bg-white2">
      <Topbar
        title="Projects"
        // buttonConfig={{ color: "dark", title: "Setup new" }}
        // buttonAction={() => navigate("/projects/new")}
      >
        <div className="ml-4 mr-auto flex flex-col justify-center h-full">
          {/* <ProjectModeToggle {...{ env, setEnv }} /> */}
        </div>
      </Topbar>
      <div className="h-full overflow-auto pb-20">
        <div className="px-8 py-6" id="target7">
          <div className="flex gap-x-3 flex-wrap">
            {projects
              .filter(({ environment }) => environment === env)
              .map((project, i) => (
                <div
                  className={getClassNames(
                    "flex flex-col py-10 px-8 rounded-lg border",
                    "w-[367px] mt-2 relative bg-white",
                    project.products === useCase
                      ? "border-body-black"
                      : "border-stroke-primary"
                  )}
                  key={project.id}
                >
                  <div className="flex items-center justify-between w-full">
                    <h5 className="header5 text-body-black">{project.name}</h5>
                    {/* <div>
                      <CustomPopover
                        className="top-2 -left-14"
                        placeholder={<Ellipsis color="#747678" />}
                        options={[
                          {
                            name: "Duplicate",
                            className: "text-blacked hover:text-body-black",
                            id: "1",
                            value: true,
                            onClick: () => {},
                          },
                          {
                            name: "Delete",
                            className: "text-red-alert hover:text-red-alert",
                            id: "2",
                            value: true,
                            onClick: () => {},
                          },
                        ]}
                      />
                    </div> */}
                  </div>
                  <p className="pr-8 mt-2 text-grey-content-tertiary flex-1">
                    {project.description}
                  </p>
                  <div className="flex gap-x-2 items-center py-2 mt-6 border border-stroke-primary rounded">
                    {projectIcons[project.products]}
                    <span className="text-body-black">
                      {projectTypeNames[project.products]}
                    </span>
                  </div>
                  <div
                    className="mt-12 flex justify-between items-center"
                    id={project.id}
                  >
                    <Button
                      title="Edit"
                      color="light"
                      className=""
                      onClick={project.products !== useCase ? () => {} : () => {
                        dispatch(setActiveProject(project));
                        dispatch(getProjectById(project.id) as any);
                        dispatch(setProjectType(project.products));
                        navigate("/projects/edit", { replace: true });
                      }}
                    />
                    {useCase === project.products && (
                      <DemoGuidePopover
                        title="Select the relevant project for this use case"
                        body="We’ve preconfigured the project for demo purposes."
                        el={() => document.getElementById(project.id)}
                        topOffset={100}
                        leftOffset={0}
                        arrowPosition="top"
                        open={popUp8}
                        setOpen={setPopUp8}
                        setPrev={setPopUp7}
                        setNext={(y) => {}}
                        onNext={() => {
                          dispatch(setActiveProject(project));
                          dispatch(getProjectById(project.id) as any);
                          dispatch(setProjectType(project.products));
                          navigate("/projects/edit", { replace: true });
                        }}
                      />
                    )}
                    <div className="flex items-center gap-x-2 rounded bg-white border border-stroke-primary py-0.5 px-2">
                      <div
                        className={getClassNames(
                          "w-2 h-2 rounded-full",
                          modeColors[project.environment]
                        )}
                      />
                      <span
                        className={getClassNames(
                          "text-base1 text-body-black lowercase first-letter:capitalize"
                        )}
                      >
                        {project.environment}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <DemoGuidePopover
            title="List of projects"
            body={`
              Manage projects in your organisation. 
              Multiple projects can be setup to support A/B testing of different configurations.       
            `}
            el={() => document.getElementById("target7")}
            topOffset={380}
            leftOffset={60}
            arrowPosition="top"
            open={popUp7}
            setOpen={setPopUp7}
            setNext={setPopUp8}
          />
        </div>
      </div>
    </div>
  );
};

export default ManageSolutions;
