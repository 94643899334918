import ReactListbox from '@/components/FormElement/ReactListbox';
import { DropDownOptions } from '@/utils/constants';
import { ReactComponent as Chatline1 } from '@images/chatlines1.svg';
import { useState } from 'react';

type SummaryHeaderProps = {
  heading: string;
  overviewData: {
    title: string;
    count: number;
    percentage: string;
    isChart: boolean;
  }[];
};

const SummaryHeader = ({ heading = '', overviewData }: SummaryHeaderProps) => {
  const [performanceSummary, setPerformanceSummary] = useState<number>(
    DropDownOptions.DateRangeOptions[0].value
  );
  return (
    <div className="mb-8">
      <div className="flex items-center justify-between mb-2">
        <p className="text-grey-content-secondary text-base leading-5 font-semibold">
          {heading}
        </p>
        <ReactListbox
          selectedOption={performanceSummary}
          onChange={setPerformanceSummary}
          Options={DropDownOptions.DateRangeOptions}
        />
      </div>
      <div className={`grid grid-cols-${overviewData.length} gap-4`}>
        {overviewData?.map((data) => (
          <div
            key={data.title}
            className="relative flex justify-between items-center p-4 rounded-lg border border-solid border-grey-stroke-primary"
          >
            <div className="relative">
              <p className="text-base font-semibold leading-5 text-grey-content-secondary mb-1">
                {data.title}
              </p>
              <h3 className="text-xl2 font-semibold leading-2xl2 text-black mb-1">
                {data.count}
              </h3>
              <span className="text-score-green font-semibold text-sm leading-sm">
                {data.percentage}
              </span>
            </div>
            <div className="relative">{data.isChart && <Chatline1 />}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SummaryHeader;
