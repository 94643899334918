import { ErrorMessage as FormikErrorMessage } from 'formik';

const ErrorMessage = ({
  name,
  color = 'text-trud-orange',
}: {
  name: string;
  color?: string;
}) => {
  return (
    <FormikErrorMessage name={name}>
      {(msg) => {
        return (
          <span className={`text-xs capitalize font-semibold block ${color} mt-1`}>
            {msg}
          </span>
        );
      }}
    </FormikErrorMessage>
  );
};

export default ErrorMessage;
