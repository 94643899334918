// import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import { toast } from "react-toastify";
import { axiosInstance } from '@utils/baseAxios';
import { Shopper } from '@/pages/dashboard/components/shopperDetails';
import { config_type } from '@/utils/constants';
import { ShopperOptions } from '@/pages/projects/checkout-and-refund/components/shopperExperienceOptions';

const CREATE_PROJECT = 'CREATE_PROJECT';
const GET_PROJECTS = 'GET_PROJECTS';

export type Config = {
  id: string;
  name: string;
  project_id: string;
  environment: string;
  config_type: 'LOW_RISK' | 'HIGH_RISK' | 'MEDIUM_RISK';
  attribute: string;
  score: string;
  created_at: string;
  updated_at: string;
};

export type FraudRiskTypeProps = {
  id: string;
  title: string;
  description: string;
};

export type Project = {
  id: string;
  project_id: string;
  api_key: string;
  name: string;
  description: string;
  environment: string;
  status: string;
  products:
    | 'SHOPPER_TRUST_INDEX'
    | 'CHECKOUT_AND_REFUND'
    | 'PAYMENT_PERSONALIZATION'
    | 'IDENTITY_AND_AUTHENTICATION'
    | 'RETURNS_ORCHESTRATOR'
    | 'CHECKOUT_AND_REFUND_MANAGER';
  fraud_risk_type_id: string;
  fraud_risk_type: FraudRiskTypeProps;
  created_at: string;
  updated_at: string;
  shopper_trust_index_configs: Config[];
  trusted_shopper_score_configs: Config[];
  low_risk_shopper_options: ShopperOptions;
  medium_risk_shopper_options: ShopperOptions;
  high_risk_shopper_options: ShopperOptions;
  low_risk_refund_details: {
    refund_execution: 'After' | 'Immediately';
    refund_trigger: 'Approved for refund' | 'Received in warehouse';
    days_to_refund: number;
    refund_mode: 'Instant refund' | 'Refund after return';
  };
  medium_risk_refund_details: {
    refund_execution: 'After' | 'Immediately';
    refund_trigger: 'Approved for refund' | 'Received in warehouse';
    days_to_refund: number;
    refund_mode: 'Instant refund' | 'Refund after return';
  };
  high_risk_refund_details: {
    refund_execution: 'After' | 'Immediately';
    refund_trigger: 'Approved for refund' | 'Received in warehouse';
    days_to_refund: number;
    refund_mode: 'Instant refund' | 'Refund after return';
  };
};

export type Item = {
  id: string;
  config_type: string;
  score: number;
  recommended?: string;
  name: string;
  description: string;
  info: string;
  risk?: string;
};

const getProjects = createAsyncThunk(
  GET_PROJECTS,
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get('projects');
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
const getProjectById = createAsyncThunk(
  'project/get',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`projects/${id}`);
      const isExistData = localStorage.getItem(`project${id}`);
      if (!isExistData)
        localStorage.setItem(`project${id}`, JSON.stringify(response.data));
      return isExistData ? JSON.parse(isExistData) : response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

const createProject = createAsyncThunk(
  CREATE_PROJECT,
  async (data: object, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('projects', data);

      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

const deleteProject = createAsyncThunk(
  'project/delete',
  async ({ id }: { id: string }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`projects/${id}`);

      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

const editProject = createAsyncThunk(
  'project/edit',
  async ({ data, id }: { data: object; id: string }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`projects/${id}/save`, data);

      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);
const publishProject = createAsyncThunk(
  'project/publish',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`projects/${id}/publish`);

      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

const getShopperTrustConfig = createAsyncThunk(
  'shopperTrustConfig/get',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `shopper_trust_index_config?project_id=${id}`,
      );
      const isExistData = localStorage.getItem(`project_${id}`);
      if (!isExistData)
        localStorage.setItem(`project_${id}`, JSON.stringify(response.data));

      return isExistData ? JSON.parse(isExistData) : response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

const getTrustedShopperConfig = createAsyncThunk(
  'trustedShopperConfig/get',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `trusted_shopper_score_config?project_id=${id}`,
      );

      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

const getNetworkShoppers = createAsyncThunk(
  'shoppers/get',
  async (id: string | null, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        'trusted_shoppers' + (id ? `?project_id=${id}` : ''),
      );

      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

const editShopperScoresConfigInBatch = createAsyncThunk(
  'shopperTrustConfig/edit',
  async ({ data }: { data: object }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        'shopper_trust_index_config/batch',
        data,
      );

      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

const editTrustedShoppersConfigInBatch = createAsyncThunk(
  'trustedShopperConfig/edit',
  async (data: { id: string; score: number }[], { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        'trusted_shopper_score_config/batch',
        data,
      );

      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);
type InitialStateProps = {
  activeProject: Project | null;
  selectedProject: Project | null;
  riskLevelFilter: { label: string; value: string };
  loading: boolean;
  error: boolean;
  shoppers: { users: Shopper[] } | null;
  hiddenShopper: string;
  shopperTrustConfig: any;
  shopperTrustConfigAttributesContribution: any;
  shopperTrustTotal: number;
  editedShopperTrustScoreForSubmission: any;
  trustedShopperConfig: any;
  trustedShopperScore: number;
  editedTrustedShoppersScoreForSubmission: any;
  projects: Project[];
  project: Project | null;
  projectType: string | null;
  projectTitle: string;
  projectEnv: string;
  shopperTrustSetupComplete: boolean;
  showPublishModal: boolean;
  showPublishSuccessModal: boolean;
  showSaveModal: boolean;
  showSaveSuccessModal: boolean;
};

const slice = createSlice({
  name: 'project',
  initialState: {
    activeProject: null,
    selectedProject: null,
    loading: false,
    error: false,
    shoppers: null,
    riskLevelFilter: { label: 'Recent', value: '' },
    hiddenShopper: 'TRU-7452-LOJ9',
    shopperTrustConfig: null,
    shopperTrustConfigAttributesContribution: {},
    shopperTrustTotal: 0,
    editedShopperTrustScoreForSubmission: null,
    trustedShopperConfig: [],
    trustedShopperScore: 0,
    editedTrustedShoppersScoreForSubmission: null,
    projects: [],
    project: null,
    projectType: null,
    projectTitle: '',
    projectEnv: 'Sandbox',
    shopperTrustSetupComplete: false,
    showPublishModal: false,
    showPublishSuccessModal: false,
    showSaveModal: false,
    showSaveSuccessModal: false,
  } as InitialStateProps,
  reducers: {
    setActiveProject: (state, { payload }) => {
      state.activeProject = payload;
    },
    setSelectedProject: (state, { payload }) => {
      state.selectedProject = payload;
    },
    setRiskLevelFilter: (state, { payload }) => {
      state.riskLevelFilter = payload;
    },
    setProjectType: (state, { payload }) => {
      state.projectType = payload;
    },
    publishModalHandler: (state, { payload }) => {
      state.showPublishModal = payload;
    },
    saveModalHandler: (state, { payload }) => {
      state.showSaveModal = payload;
    },
    publishSuccessModalHandler: (state, { payload }) => {
      state.showPublishSuccessModal = payload;
    },
    saveSuccessModalHandler: (state, { payload }) => {
      state.showSaveSuccessModal = payload;
    },
    setProjectEnv: (state, { payload }) => {
      state.projectEnv = payload;
    },
    setProjectTitle: (state, { payload }) => {
      state.projectTitle = payload;
    },
    setShopperTrustTotal: (state, { payload }) => {
      state.editedShopperTrustScoreForSubmission = payload;
      state.shopperTrustTotal = Object.keys(payload).reduce(
        (accumulator, currentValue) => {
          return accumulator + parseInt(payload[currentValue]);
        },
        0,
      );
    },

    setTrustedShopperTotal: (
      state,
      {
        payload,
      }: {
        payload: Record<string, { score: number; config_type: config_type }>;
      },
    ) => {
      state.editedTrustedShoppersScoreForSubmission = payload;
      const t = Object.entries(payload).find((item) => {
        return item[1].config_type === 'LOW_RISK';
      });
      if (t) {
        state.trustedShopperScore = t[1].score;
      }
    },
    clearHiddenShopper: (state, { payload }) => {
      state.hiddenShopper = 'undefined';
    },
    setShopperTrustSetupComplete: (state) => {
      state.shopperTrustSetupComplete = !state.shopperTrustSetupComplete;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        // Get all projects
        getProjects.rejected,
        (state, { payload }) => {
          state.loading = false;
          // toast.error(payload?.message, { autoClose: 1000 });
        },
      )
      .addCase(getProjects.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.projects = payload;
        if (!state.activeProject) {
          if (payload.length > 0) {
            state.activeProject = payload[0];
          }
        }
      })
      .addCase(
        // Get project by Id
        getProjectById.rejected,
        (state, { payload }) => {
          state.loading = false;

          // toast.error(payload?.message, { autoClose: 1000 });
        },
      )
      .addCase(getProjectById.fulfilled, (state, { payload }) => {
        if (!state.activeProject) return;
        state.loading = false;
        state.activeProject = payload.data.find(
          (proj: Project) => proj.environment === 'SANDBOX',
        );
        state.shopperTrustTotal = (
          payload.data[0] as Project
        )?.shopper_trust_index_configs
          ?.filter(
            (attr) => attr.environment === state?.activeProject?.environment,
          )
          .reduce((accumulator, currentValue) => {
            return accumulator + parseInt(currentValue.score);
          }, 0);
        state.trustedShopperScore = 80;
      })
      .addCase(
        // Create Project
        createProject.pending,
        (state, { meta: { arg } }) => {
          state.loading = true;
        },
      )
      .addCase(createProject.rejected, (state, { payload }) => {
        state.loading = false;
        // toast.error(payload.message, { autoClose: 1000 });
      })
      .addCase(createProject.fulfilled, (state, { payload: { data } }) => {
        state.loading = false;
        state.activeProject = data;
      })
      .addCase(
        // Delete Project
        deleteProject.pending,
        (state, { meta: { arg } }) => {
          state.loading = true;
        },
      )
      .addCase(deleteProject.rejected, (state, { payload }) => {
        state.loading = false;
        // toast.error(payload.message, { autoClose: 1000 });
      })
      .addCase(deleteProject.fulfilled, (state, { payload: { data } }) => {
        state.loading = false;
      })
      .addCase(
        // Save Project
        editProject.pending,
        (state, { meta: { arg } }) => {
          state.loading = true;
        },
      )
      .addCase(editProject.rejected, (state, { payload }) => {
        state.loading = false;
        // toast.error(payload?.message, { autoClose: 1000 });
      })
      .addCase(editProject.fulfilled, (state, { payload: { data } }) => {
        state.loading = false;
      })
      .addCase(
        // Save Project
        publishProject.pending,
        (state, { meta: { arg } }) => {
          state.loading = true;
        },
      )
      .addCase(publishProject.rejected, (state, { payload }) => {
        state.loading = false;
        // toast.error(payload?.error, { autoClose: 1000 });
      })
      .addCase(publishProject.fulfilled, (state, { payload: { data } }) => {
        state.loading = false;
      })
      .addCase(
        // Get Shopper Trust Config
        getShopperTrustConfig.pending,
        (state, { meta: { arg } }) => {
          state.loading = true;
        },
      )
      .addCase(getShopperTrustConfig.rejected, (state, { payload }) => {
        state.loading = false;
        // toast.error(payload?.message, { autoClose: 1000 });
      })
      .addCase(getShopperTrustConfig.fulfilled, (state, { payload }) => {
        state.loading = false;

        state.shopperTrustConfig = payload.config.filter(
          (attr: Config) => attr.environment === 'SANDBOX',
        );
        const transformedData = state.shopperTrustConfig.reduce(
          (acc: any, obj: any) => {
            acc[obj.name] = obj.shopper_trust_index_sub_categories;
            return acc;
          },
          {},
        );
        state.shopperTrustConfigAttributesContribution = transformedData;
        state.shopperTrustTotal = payload.config
          .filter(
            (attr: Config) =>
              attr.environment === state.activeProject?.environment,
          )
          .reduce((accumulator: number, currentValue: Config) => {
            return accumulator + parseInt(currentValue.score);
          }, 0);
      })
      .addCase(
        // Get Trusted Shopper Config
        getTrustedShopperConfig.pending,
        (state, { meta: { arg } }) => {
          state.loading = true;
        },
      )
      .addCase(getTrustedShopperConfig.rejected, (state, { payload }) => {
        state.loading = false;
        // toast.error(payload?.message, { autoClose: 1000 });
      })
      .addCase(getTrustedShopperConfig.fulfilled, (state, { payload }) => {
        state.loading = false;

        state.trustedShopperConfig = payload?.config?.filter(
          (attr: Config) => attr.environment === 'SANDBOX',
        );
        state.trustedShopperScore = state.trustedShopperConfig[0]?.score
          ? parseInt(state.trustedShopperConfig[0].score)
          : 80;
      })
      .addCase(
        // Get Network Shoppers
        getNetworkShoppers.pending,
        (state, { meta: { arg } }) => {
          state.loading = true;
        },
      )
      .addCase(getNetworkShoppers.rejected, (state, { payload }) => {
        state.loading = false;
        // toast.error(payload?.message, { autoClose: 1000 });
      })
      .addCase(getNetworkShoppers.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.shoppers = payload;
      })
      .addCase(
        // Edit Shopper trust Config
        editShopperScoresConfigInBatch.pending,
        (state, { meta: { arg } }) => {
          state.loading = true;
        },
      )
      .addCase(
        editShopperScoresConfigInBatch.rejected,
        (state, { payload }) => {
          state.loading = false;
          // toast.error(payload?.message, { autoClose: 1000 });
        },
      )
      .addCase(
        editShopperScoresConfigInBatch.fulfilled,
        (state, { payload: { data } }) => {
          state.loading = false;
        },
      )
      .addCase(
        // Edit Trusted Shopper Trust Config
        editTrustedShoppersConfigInBatch.pending,
        (state, { meta: { arg } }) => {
          state.loading = true;
        },
      )
      .addCase(
        editTrustedShoppersConfigInBatch.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = true;
          // toast.error(payload?.message, { autoClose: 1000 });
        },
      )
      .addCase(
        editTrustedShoppersConfigInBatch.fulfilled,
        (state, { payload: { data } }) => {
          state.loading = false;
        },
      );
  },
});

export const {
  setActiveProject,
  setSelectedProject,
  setRiskLevelFilter,
  setProjectType,
  setProjectTitle,
  setShopperTrustTotal,
  setTrustedShopperTotal,
  clearHiddenShopper,
  setShopperTrustSetupComplete,
  publishModalHandler,
  saveModalHandler,
  saveSuccessModalHandler,
  publishSuccessModalHandler,
} = slice.actions;
export {
  getProjects,
  getProjectById,
  createProject,
  editProject,
  publishProject,
  deleteProject,
  getShopperTrustConfig,
  getTrustedShopperConfig,
  getNetworkShoppers,
  editShopperScoresConfigInBatch,
  editTrustedShoppersConfigInBatch,
};
export default slice.reducer;
