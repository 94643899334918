const SHOPPER_TRUST_INDEX = 'SHOPPER_TRUST_INDEX';
const CHECKOUT_AND_REFUND = 'CHECKOUT_AND_REFUND';
const RETURNS_ORCHESTRATOR = 'RETURNS_ORCHESTRATOR';
const IDENTITY_AND_AUTHENTICATION = 'IDENTITY_AND_AUTHENTICATION';
const PAYMENT_PERSONALIZATION = 'PAYMENT_PERSONALIZATION';

export type config_type = 'LOW_RISK' | 'MEDIUM_RISK' | 'HIGH_RISK';

export {
  SHOPPER_TRUST_INDEX,
  PAYMENT_PERSONALIZATION,
  CHECKOUT_AND_REFUND,
  RETURNS_ORCHESTRATOR,
  IDENTITY_AND_AUTHENTICATION,
};

export const DropDownOptions = Object.freeze({
  teams: [
    {
      label: 'General',
      value: 'General',
    },
    {
      label: 'Management',
      value: 'Management',
    },
  ],
  roles: [
    {
      label: 'Admin',
      value: 'Admin',
      description: 'Can fully configure and edit application',
    },
    {
      label: 'Editor',
      value: 'Editor',
      description: 'Can edit but cannot perform admin tasks',
    },
    {
      label: 'Viewer',
      value: 'Viewer',
      description: 'Can only view',
    },
  ],
  listBoxOptions: [
    {
      label: 'All',
      value: 'all',
    },
    {
      label: '/api/networksearch',
      value: '/api/networksearch',
    },
    {
      label: '/api/projects',
      value: '/api/projects',
    },
    {
      label: '/api/fraudprofiles',
      value: '/api/fraudprofiles',
    },
    {
      label: '/api/trudocs',
      value: '/api/trudocs',
    },
    {
      label: '/api/shoppertrustindex',
      value: '/api/shoppertrustindex',
    },
  ],

  DateRangeOptions: [
    {
      value: 30,
      label: 'Last 30 Days',
    },
    {
      value: 20,
      label: 'Last 20 Days',
    },
    {
      value: 15,
      label: 'Last 15 Days',
    },
    {
      value: 10,
      label: 'Last 10 Days',
    },
    {
      label: 'Last 5 Days',
      value: 5,
    },
  ],
});

export const ROLES = Object.freeze({
  ADMIN: 'Admin',
  EDITOR: 'Editor',
  VIEWER: 'Viewer',
});

export const ShopperConfigSequence = [
  'IDENTITY',
  'MERCHANT_DATA',
  'NETWORK_INTELLIGENCE',
  'BANKING_HISTORY',
  'REAL_WORLD_DATA',
];
