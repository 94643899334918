import { useContext } from "react";
import { useNavigate } from "react-router-dom";

// Context
import { Button } from "@components/button";
import { DemoGuidePopoverContext } from "@/context/demoGuidePopover";

// Assets
import { ReactComponent as CloseIcon } from "@icons/close.svg";
import ShopperPerson from "@images/shopper-person.png";

const EndDemoPopup = () => {
  const navigate = useNavigate();

  const { setExploreDemo, setShowExploreDemo } = useContext(
    DemoGuidePopoverContext
  );
  const useCase = localStorage.getItem("product");

  return (
    <div className="h-screen overflow-auto absolute inset-0 bg-[#3333334D]">
      <div className="z-20 flex items-center justify-center h-screen">
        <div className="relative rounded-2xl overflow-hidden bg-white max-w-[528px]">
          <img src={ShopperPerson} alt="Shopper Person" />
          <CloseIcon
            onClick={() => setShowExploreDemo(false)}
            className="cursor-pointer rounded-full absolute right-2 top-2"
          />
          <div className="py-8 px-10 bg-white">
            <h3 className="header4">
              {useCase === "PAYMENT_PERSONALIZATION"
                ? "Explore the demo further"
                : "What would you like to do next?"}
            </h3>
            <p className="paragraph1 mt-2 text-grey-content-secondary">
              {useCase === 'PAYMENT_PERSONALIZATION'
                ? `Switch to the Consumer Demo to experience personalised payments based on fraud risk as a consumer, or explore more features of the console dashboard.`
                : 'Explore other use cases using embedded consumer intelligence or return to the menu to view our other materials.'}
            </p>
            <div className="flex gap-2 items-center mt-12">
              <a
                href={
                  useCase === "PAYMENT_PERSONALIZATION"
                    ? "https://mastercardconsumerdemo.trudenty.com"
                    : "https://experiencemastercard.trudenty.com/menu"
                }
              >
                <Button
                  title={
                    useCase === "PAYMENT_PERSONALIZATION"
                      ? "Go to Consumer Demo"
                      : "Back to menu"
                  }
                  color={
                    useCase === "PAYMENT_PERSONALIZATION" ? "dark" : "light"
                  }
                  className=""
                />
              </a>
              <a
                href={
                  useCase === "PAYMENT_PERSONALIZATION"
                    ? "#"
                    : "https://experiencemastercard.trudenty.com/use-cases"
                }
              >
                <Button
                  title={
                    useCase === "PAYMENT_PERSONALIZATION"
                      ? "Explore console dashboard"
                      : "Explore other use cases"
                  }
                  color={
                    useCase === "PAYMENT_PERSONALIZATION" ? "light" : "dark"
                  }
                  className=""
                  onClick={() => {
                    setExploreDemo(false);
                    setShowExploreDemo(false);
                    navigate("/");
                  }}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EndDemoPopup;
