import { Link } from "react-router-dom";

// Assets
import { ReactComponent as GearIcon } from "@icons/gear2.svg";
import { ReactComponent as MemberIcon } from "@icons/member.svg";
import { ReactComponent as TableIcon } from "@icons/table.svg";
import PlusIcon from "@icons/plus";
import { ReactComponent as ScoreIcon } from "@icons/score.svg";

const GetStarted = () => {
  return (
    <div className="sticky top-0">
      <h2 className="font-semibold text-xl2">Get started</h2>
      <div className="mt-4 flex-1 grid grid-cols-1 xl:grid-cols-2 gap-6 lg:max-w-[1088px]">
        <div className="rounded-lg group bg-igrey-20 hover:bg-white2 min-h-[384px] flex-1 flex max-w-[544px] min-w-[444px] pt-12 pl-16">
          <div className="bg-white/[0.91] flex-1 rounded-tl-lg">
            <Link to="/projects">
              <div className="flex gap-x-3 items-center pt-7 pl-7 pr-9 pb-6 border-b border-b-grey">
                <button
                  type="button"
                  className="rounded bg-grey-70 w-10 h-10 flex items-center justify-center  focus:outline-none focus-visible:ring-0"
                >
                  <PlusIcon size="12" />
                </button>
                <h3 className="text-xl mr-1">Create a project</h3>
              </div>
            </Link>
            <div className="pt-8 pl-7 flex flex-col gap-y-11">
              <div className="flex gap-x-4 items-center mt-3">
                <ScoreIcon className="w-10 h-10" />
                <span className="text-grey-content-secondary text-base1 leading-5 max-w-[280px]">
                Get started with knowing your consumers by creating a project.
                </span>
              </div>
              <div className="flex gap-x-4 items-center">
                <GearIcon className="w-10 h-10" />

                <span className="text-grey-content-secondary text-base1 leading-5 max-w-[280px]">
                Publish a project, integrate and improve your business’ workflows.
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="rounded-lg group bg-igrey-20 hover:bg-white2 min-h-[384px] flex-1 flex max-w-[544px] min-w-[444px] pt-12 pl-16">
          <div className="bg-white/[0.96] flex-1 rounded-tl-lg">
            <Link to="/users">
              <div className="flex gap-x-3 items-center pt-7 pl-7 pr-9 pb-6 border-b border-b-grey">
                <button
                  type="button"
                  className="rounded-full bg-grey-70 w-10 h-10 flex items-center justify-center  focus:outline-none focus-visible:ring-0"
                >
                  <PlusIcon size="12" />
                </button>
                <h3 className="text-xl">Manage your organization</h3>
              </div>
            </Link>
            <div className="pt-8 pl-7 flex flex-col gap-y-11">
              <div className="flex gap-x-4 items-center mt-3">
              <MemberIcon className="w-10 h-10" />
                <span className="text-grey-content-secondary text-base1 leading-5 max-w-[280px]">
                  Create a member’s profile and populate your team.
                </span>
              </div>
              <div className="flex gap-x-4 items-center">
                <TableIcon className="w-10 h-10" />
                <span className="text-grey-content-secondary text-base1 leading-5 max-w-[280px]">
                  Add multiple members in bulk by uploading a CSV sheet or
                  connecting to an external source.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetStarted;
