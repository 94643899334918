import ReactListbox from '@/components/FormElement/ReactListbox';
import RenderChart from '@/components/RenderChart';
import { DropDownOptions } from '@/utils/constants';
import { useState } from 'react';

const rowTitle = [
  {
    title: '/api/networksearch',
    fillPercentage: 100,
    value: 6060,
  },
  {
    title: '/api/projects',
    fillPercentage: 50,
    value: 3030,
  },
  {
    title: '/api/fraudprofiles',
    fillPercentage: 28,
    value: 1520,
  },
  {
    title: '/api/trudocs',
    fillPercentage: 21,
    value: 1515,
  },
  {
    title: '/api/shoppertrustindex',
    fillPercentage: 12,
    value: 758,
  },
];
interface GraphRepresentationWithDataProps {
  title: string;
  dataPercentageTitle: string;
  titleValue: number | string;
  titleDiffPer: string;
  defaultValue: string | number;
  isWebHook?: boolean;
  chartData: {
    name: string;
    data: any[];
  }[];
  rowData: { title: string; fillPercentage: number; value: number | string }[];
}

const GraphRepresentationWithData = ({
  title,
  dataPercentageTitle,
  titleValue,
  titleDiffPer,
  defaultValue,
  isWebHook = false,
  chartData,
  rowData,
}: GraphRepresentationWithDataProps) => {
  const [range, setRange] = useState<number>(30);
  const [currentData, setData] = useState<string>('all');
  const renderRow = () => {
    return rowData.map((row, index) => (
      <div className="flex items-center mb-3" key={index}>
        <div className="relative w-2/5">
          <p className="text-base font-semibold leading-lg -tracking-0.2 text-grey-content-secondary">
            {row.title.includes('/api/') ? (
              <>
                <span className="text-grey-content-tertiary">/api/</span>
                {row.title.split('/api/')[1]}
              </>
            ) : (
              row.title
            )}
          </p>
        </div>
        <div style={{ width: `${row.fillPercentage * 0.6}%` }}>
          <div className="bg-grey-stroke-primary text-sm font-semibold py-3 leading-4 text-end ">
            <span className="block mx-5">{row.value}</span>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div className="py-6 px-4 border border-solid border-grey-stroke-primary rounded-lg w-2/4">
      <div className="relative flex justify-between items-start">
        <div className="relative">
          <p className="text-base font-semibold leading-5 text-grey-content-secondary mb-1">
            {title}
          </p>
          <h3 className="text-xl2 font-semibold leading-2xl2 text-black mb-1">
            {titleValue}
          </h3>
          <span className="text-score-green font-semibold text-sm leading-sm">
            {titleDiffPer}
          </span>
        </div>
        <div className="relative">
          <div className="flex items-center gap-2.5">
            <ReactListbox
              selectedOption={currentData}
              onChange={setData}
              Options={DropDownOptions.listBoxOptions}
            />
            <ReactListbox
              selectedOption={range}
              onChange={setRange}
              Options={DropDownOptions.DateRangeOptions}
            />
          </div>
        </div>
      </div>
      <div className="text-center my-5">
        <RenderChart
          chartOptions={{
            stroke: {
              colors: [
                isWebHook
                  ? '#EFB604'
                  : currentData === defaultValue
                    ? '#02BD8E'
                    : '#18A0FB',
                '#CFCFCF',
              ],
              dashArray: [0, 5],
            },
            markers: {
              colors: [
                isWebHook
                  ? '#EFB604'
                  : currentData === defaultValue
                    ? '#02BD8E'
                    : '#18A0FB',
                '#CFCFCF',
              ],
            },
            tooltip: {
              marker: {
                fillColors: [
                  isWebHook
                    ? '#EFB604'
                    : currentData === defaultValue
                      ? '#02BD8E'
                      : '#18A0FB',
                  '#CFCFCF',
                ],
              },
            },
          }}
          series={
            chartData && chartData?.length > 0 && currentData === defaultValue
              ? [chartData[0]]
              : chartData
          }
          height={250}
        />
      </div>
      <div className="relative">
        <div className="flex justify-between w-full mb-5">
          <h6 className="text-sm1 font-semibold leading-lg text-body-black mb-5">
            {dataPercentageTitle}
          </h6>
          <span className="block text-sm1 font-semibold leading-lg text-grey-content-secondary cursor-pointer">
            View All
          </span>
        </div>
        {renderRow()}
      </div>
    </div>
  );
};

export default GraphRepresentationWithData;
