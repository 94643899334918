import GraphRepresentationWithData from './GraphRepresentationWithData';

const EndPointUsage = [
  {
    title: '/api/networksearch',
    fillPercentage: 90,
    value: 90,
  },
  {
    title: '/api/shoppertrustindex',
    fillPercentage: 60,
    value: 60,
  },
  {
    title: '/api/fraudprofile',
    fillPercentage: 30,
    value: 30,
  },
];

const WebhookUpdates = [
  {
    title: 'fraudprofile_update',
    fillPercentage: 100,
    value: 663,
  },
  {
    title: 'algorithm_update_segmentation',
    fillPercentage: 70,
    value: 350,
  },
  {
    title: 'algorithm_update_recommendation',
    fillPercentage: 40,
    value: 212,
  },
];

const AverageAPILatency = [
  {
    title: '/api/trudocs',
    fillPercentage: 100,
    value: '270ms',
  },
  {
    title: '/api/networksearch',
    fillPercentage: 75,
    value: '170ms',
  },
  {
    title: '/api/fraudprofile',
    fillPercentage: 60,
    value: '150ms',
  },
  {
    title: '/api/shoppertrustindex',
    fillPercentage: 40,
    value: '150ms',
  },
  {
    title: '/api/projects',
    fillPercentage: 20,
    value: '150ms',
  },
];

const ErrorDistribution = [
  {
    title: 'Timeouts',
    fillPercentage: 100,
    value: 22,
  },
  {
    title: 'Connection error',
    fillPercentage: 60,
    value: 16,
  },
  {
    title: 'Webhook delivery error',
    fillPercentage: 40,
    value: 8,
  },
  {
    title: 'Server error',
    fillPercentage: 20,
    value: 4,
  },
  {
    title: 'Client error',
    fillPercentage: 10,
    value: 2,
  },
];

const PartnerPerformanceData = () => {
  return (
    <div>
      <div className="mb-8">
        <div className="flex items-center justify-between mb-2">
          <p className="text-grey-content-secondary text-base leading-5 font-semibold">
            Usage
          </p>
        </div>
        <div className="flex gap-4">
          <GraphRepresentationWithData
            chartData={[{ name: '/networksearch', data: [10, 20, 30, 40, 50] }]}
            dataPercentageTitle="Top Endpoints"
            defaultValue={'all'}
            title="Endpoint usage"
            titleDiffPer="+20%"
            titleValue={180}
            rowData={EndPointUsage}
          />
          <GraphRepresentationWithData
            chartData={[
              { name: 'fraudprofile_update', data: [100, 50, 300, 225, 275, 10, 310] },
            ]}
            dataPercentageTitle="Webhook update distribution"
            defaultValue={'all'}
            title="Webhook updates"
            titleDiffPer="+20%"
            titleValue={1225}
            rowData={WebhookUpdates}
          />
        </div>
      </div>
      <div className="mb-8">
        <div className="flex items-center justify-between mb-2">
          <p className="text-grey-content-secondary text-base leading-5 font-semibold">
            Quality
          </p>
        </div>
        <div className="flex gap-4">
          <GraphRepresentationWithData
            chartData={[
              {
                name: '/api/trudocs',
                data: [10, 20, 15, 5, 100, 80, 36, 58, 69, 20, 1, 58],
              },
            ]}
            dataPercentageTitle="Endpoint latency"
            defaultValue={'all'}
            title="Average API latency"
            titleDiffPer="Excellent"
            titleValue={`250ms`}
            rowData={AverageAPILatency}
          />
          <GraphRepresentationWithData
            chartData={[{ name: 'Timeouts', data: [100, 50, 300, 225, 275, 10, 310] }]}
            dataPercentageTitle="Error distribution"
            defaultValue={'all'}
            title="Error rate"
            titleDiffPer="-20%"
            titleValue={'20%'}
            rowData={ErrorDistribution}
          />
        </div>
      </div>
    </div>
  );
};

export default PartnerPerformanceData;
