import PartnerPerformanceData from './PartnerPerformanceData';
import SummaryHeader from './SummaryHeader';

const PartnerPerformance = () => {
  const overviewData = [
    {
      title: 'API calls',
      count: 120,
      percentage: '+5%',
      isChart: true,
    },
    {
      title: 'Webhook events',
      count: 2650,
      percentage: '+25%',
      isChart: true,
    },
  ];
  return (
    <div className="relative bg-white">
      <SummaryHeader heading="Performance overview" overviewData={overviewData} />
      <PartnerPerformanceData />
    </div>
  );
};

export default PartnerPerformance;
