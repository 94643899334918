// ** CSS **
import './style/Loader.css';

interface LoadersProps {
  className?: string;
}
const Spinner = ({ className }: LoadersProps) => {
  return (
    <span
      className={`animate-spin h-5 w-5 inline-block border-[3px] border-solid border-l-body-black border-grey-content-tertiary rounded-full ${
        className ?? ''
      }`}
    />
  );
};

export default Spinner;
