import React, { Fragment, useState } from "react";
import { Transition, Listbox } from "@headlessui/react";

// Helpers
import { getClassNames, modeColors } from "@utils/helpers";

// Assets
import { ReactComponent as CaretIcon } from "@icons/arrow_down.svg";
import { useSelector } from "react-redux";
import { IRootState } from "@/redux/store";

type ProjectStateToggleProps = {
  value: string;
  onChange: (value: string) => void;
  className?: string;
  position?: string;
};

const ProjectStateToggle = ({
  value,
  onChange,
  className = "rounded-2xl",
  position = "top-10 right-2",
}: ProjectStateToggleProps) => {
  const [mode, setMode] = useState("SANDBOX");
  const options = ["LIVE", "SANDBOX"];

  return (
    <Listbox value={value} onChange={setMode}>
      {({ open }) => {
        return (
          <div
            className={getClassNames(
              "relative border border-stroke bg-white py-1 px-2",
              className
            )}
          >
            <Listbox.Button
              className={getClassNames(
                "flex items-center gap-x-2 justify-between w-full",
                "bg-white4 border-0 text-left cursor-pointer",
                "focus:outline-none focus:ring-0 focus:border-0 w-full"
              )}
            >
              <div className="flex gap-x-2 items-center">
                <div
                  className={getClassNames(
                    "w-[10px] h-[10px] min-w-[10px] min-h-[10px] rounded-full",
                    mode === "LIVE" ? "bg-green-alert" : "bg-trud-orange"
                  )}
                />
                <span className={getClassNames("text-sm1  text-body-black first-letter:capitalize lowercase")}>
                  {mode}
                </span>
              </div>

              <span className="pointer-events-none">
                <CaretIcon />
              </span>
            </Listbox.Button>
            <div className="relative" id="positionEl" />

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={getClassNames(
                  "absolute w-max bg-white z-[100] -m-2 px-2 py-1 shadow-[0px_6px_10px_2px_rgba(218,218,219,0.5)] rounded",
                  "text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm ",
                  "min-w-[8rem]",
                  position
                )}
              >
                {options?.map((option) => (
                  <Listbox.Option
                    key={option}
                    className={({ active }) =>
                      getClassNames(
                        "cursor-pointer py-1 select-none relative rounded "
                      )
                    }
                    value={option}
                  >
                    <div
                      className={getClassNames(
                        "flex gap-x-2 items-center w-full px-2 py-1 hover:bg-grey rounded",
                        mode === option ? "bg-grey" : ""
                      )}
                    >
                      <div
                        className={getClassNames(
                          "w-[10px] h-[10px] min-w-[10px] min-h-[10px] rounded-full",
                          mode === option
                            ? modeColors[option]
                            : "bg-grey-content-quaternary"
                        )}
                      />
                      <span
                        className={getClassNames(
                          "block truncate w-full text-sm1 leading-5 font-semibold text-body-black first-letter:capitalize lowercase"
                        )}
                      >
                        {option}
                      </span>
                    </div>
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        );
      }}
    </Listbox>
  );
};

export const ProjectEnvIndicator = () => {
  const { projectEnv } = useSelector((state: IRootState) => state.projectSlice);

  return (
    <div
      className={getClassNames(
        "relative border border-stroke bg-white py-1 px-2 rounded",
      )}
    >
      <div
        className={getClassNames(
          "flex items-center gap-x-2 justify-between w-full",
          "bg-white4 border-0 text-left cursor-pointer",
          "focus:outline-none focus:ring-0 focus:border-0 w-full"
        )}
      >
        <div className="flex gap-x-2 items-center">
          <div
            className={getClassNames(
              "w-[10px] h-[10px] min-w-[10px] min-h-[10px] rounded-full",
              projectEnv === "Live" ? "bg-green-alert" : "bg-trud-orange"
            )}
          />
          <span className={getClassNames("text-sm1  text-body-black")}>
            {projectEnv}
          </span>
        </div>
      </div>
    </div>
  );
};

type ProjectModeToggleProps = {
  env: string;
  setEnv: (env: string) => void;
};

export const ProjectModeToggle = ({ env, setEnv }: ProjectModeToggleProps ) => {
  const [mode, setMode] = useState(env === "LIVE" ? 0 : 1)
  const options = ["LIVE", "SANDBOX"];

  return (
    <div
      className={getClassNames(
        "relative max-h-[2rem] flex bg-stroke4 text-black border border-stroke rounded translate-x-0"
      )}
      style={{
        gridTemplateColumns: `repeat(${options.length}, minmax(0, 1fr))`,
      }}
    >
      <div
        className={getClassNames(
          "absolute bg-white transition-transform duration-300 h-full border border-stroke",
          mode === 0 ? "rounded-s" : "",
          mode === options.length - 1 ? "rounded-r" : ""
        )}
        style={{
          width: `${mode === 0 ? 59 : 88}px`,
          transform: `translate(${mode === 0 ? 0 : 59}px)`,
        }}
      />
      {options.map((option, i) => (
        <div
          key={option}
          onClick={() => {
            setMode(i)
            setEnv(options[i])
          }}
          className={getClassNames(
            "h-full flex items-center gap-x-2 px-4 py-1 z-10 border-x border-stroke"
          )}
          style={{
            width: `${i === 0 ? 59 : 88}px`,
          }}
        >
          <span
            className={getClassNames(
              "cursor-pointer first-letter:capitalize lowercase",
              mode === i ? "text-black" : "text-grey-content-secondary"
            )}
          >
            {option}
          </span>
        </div>
      ))}
    </div>
  );
};

export default ProjectStateToggle;
