import React, { useState } from 'react';
import { useSelector } from 'react-redux';

// Redux
import { IRootState } from '@redux/store';

// Utils
import { getClassNames, getRiskLevel, getRiskTitle } from '@utils/helpers';

// Contexts
import { DemoGuidePopoverContext } from '@context/demoGuidePopover';

// Components
import { Button } from '@components/button';
import CaretSm from '@icons/CaretSm';
import DemoGuidePopover from '@components/DemoGuidePopover';
import { RiskTag, ScoreBubble } from './shoppersCharts';

import CustomHistogram from './CustomHistogram';

export type Shopper = {
  fullname: string;
  id: string;
  attributes: any[];
  project_identifier: string;
  scores: any;
  inactive: boolean;
  created_at: string;
  risk_level: string;
};

type ShopperDetailsProps = {
  selectedShopper?: Shopper;
};

const ShopperDetails: React.FC<ShopperDetailsProps> = ({ selectedShopper: shopper }) => {
  const {
    attributes = [],
    project_identifier = '',
    inactive = false,
    risk_level = 'LOW_RISK_SHOPPER',
  } = shopper ? shopper : {};

  const { popUp4, setPopUp4, setPopUp3, setPopUp5, setPopUp6 } = React.useContext(
    DemoGuidePopoverContext
  );

  const totalScore = attributes?.reduce((accumulator, currentValue) => {
    return accumulator + parseInt(currentValue?.score);
  }, 0);

  const { activeProject } = useSelector((state: IRootState) => state.projectSlice);
  const [openedRow, setOpenedRow] = useState<number | undefined>();

  return (
    <div className="relative bg-white overflow-auto" id="target4">
      <DemoGuidePopover
        title="3. Shopper Trust Index"
        body={`The 360 view of a consumer’s profile, including verified identity information, 
        detail on fraud risk along multiple dimensions (assessed through the lens of your 
          organisation’s configurations).`}
        el={() => document.getElementById('target4')}
        topOffset={-240}
        leftOffset={-100}
        arrowPosition="bottom"
        open={popUp4}
        setOpen={setPopUp4}
        setPrev={setPopUp3}
        setNext={
          activeProject?.products === 'CHECKOUT_AND_REFUND_MANAGER'
            ? setPopUp5
            : setPopUp6
        }
      />
      <div className="bg-white2 py-6 pb-10 px-8 flex justify-between">
        <div className="flex flex-col">
          <ScoreBubble {...{ score: totalScore, inactive, risk_level }} />
          <RiskTag
            risk={getRiskLevel(risk_level)}
            title={getRiskTitle(getRiskLevel(risk_level))}
            inactive={inactive}
            className="mt-2 border border-stroke rounded-sm px-2"
          />
        </div>
        <div className="relative flex-1 h-[160px]  max-w-[400px]">
          <div className="flex  justify-between left-0 right-0">
            <span className="text-grey-content-tertiary">Score History</span>
            <Button
              title="2023"
              className="pt-[2px] -mt-1 pb-[2px] px-[6px] text-sm1 text-body-black"
              suffixIcon={<CaretSm color="#747678" className="ml-1 rotate-180" />}
            />
          </div>
          <CustomHistogram {...{ totalScore, project_identifier }} />
        </div>
      </div>
      {inactive && (
        <div className="mx-8 flex items-center justify-between p-3 rounded bg-stroke-secondary border border-stroke-primary">
          <p className="text-body-black max-w-[544px]">
            Access to this Shopper Trust Index expired on July 30, 2023. Request new
            permissions to get an updated score.
          </p>
          <Button
            title="Request Renewal"
            color="light"
            category="2"
            className="text-body-black text-sm1 px-4 py-2"
          />
        </div>
      )}
      {attributes?.map((attr, i) => (
        <div
          className={getClassNames('cursor-pointer', openedRow === i ? 'group' : '')}
          key={`${attr.id}`}
        >
          <div
            className={getClassNames(
              'grid grid-cols-[45px,1fr,200px]  divide-x-[1px divide-grey-10',
              parseInt(attr.score.toString()) === 0 ? 'opacity-50' : ''
            )}
            key={attr.name}
            onClick={() => setOpenedRow(openedRow === i ? undefined : i)}
          >
            <div className="text-sm text-grey-content-secondary text-center py-6">
              {i + 1}
            </div>
            <div className="py-6 px-4 border-l border-l-grey-10 group">
              <div className="flex items-center gap-x-2">
                <span className="text-lg text-body-black">{attr.name}</span>
                <CaretSm
                  color="#A7A8AC"
                  className={getClassNames(openedRow === i ? 'rotate-0' : 'rotate-180')}
                />
              </div>
              <p className="mt-2 text-grey-content-tertiary relative">
                {attr.description}
              </p>
            </div>
            <div className="flex flex-col gap-y-1 items-center justify-center py-6">
              <span
                className={getClassNames(
                  'rounded px-2 border-grey-stroke-primary text-sm1 bg-stroke w-[42px] h-[42px]',
                  'flex items-center justify-center'
                )}
              >
                {attr.score > 0 ? parseInt(attr.score) : ' -'}
              </span>
            </div>
          </div>
          <div className="grid-reveal bg-primary">
            <div className="">
              <div className="grid grid-cols-[45px,1fr,168px] text-grey-content-secondary">
                <span />
                <span>Properties</span>
                <span className="w-full text-center">Recommended</span>
              </div>

              <div className="flex flex-col mt-3 ml-[45px] gap-y-5 pt-3 rounded border border-stroke-secondary divide-x-[1px] divide-grey-stroke-tertiary">
                {attr?.sub_category?.map(
                  (
                    {
                      id,
                      name,
                      description,
                      score,
                    }: {
                      id: number;
                      name: string;
                      description: string;
                      score: number;
                    },
                    i: number
                  ) => (
                    <div
                      className="relative grid grid-cols-[1fr,168px] pl-4"
                      key={`sub_${id}`}
                    >
                      <div className="">
                        <div className="flex items-center gap-x-2">
                          <div className="w-[9px] h-[9px] rounded-sm bg-stroke-secondary" />
                          <span className="text-body-black">{name}</span>
                        </div>
                        <p className="mt-2 text-grey-content-tertiary">{description}</p>
                      </div>
                      <div className="flex flex-col gap-y-1 items-center justify-center py-4">
                        <span
                          className={getClassNames(
                            'rounded px-2 border-grey-stroke-primary text-sm1 bg-stroke w-[42px] h-[42px]',
                            'flex items-center justify-center'
                          )}
                        >
                          {score > 0 ? parseInt(score.toString()) : ' -'}
                        </span>
                      </div>
                      {i < attr?.sub_category?.length - 1 && (
                        <hr className="absolute inset-x-0 bottom-0 border-stroke-secondary" />
                      )}
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ShopperDetails;
