import { Menu } from "@headlessui/react";

// Helpers
import { getClassNames } from "@utils/helpers";

// Components
import { InputContainer } from "@components/input";
import ThemeSample from "@icons/themeSample";

// Assets
import { ReactComponent as CaretIcon } from "@icons/arrow_down.svg";
import RadioButton from "@components/RadioButton";

const ThemeSelect = ({ theme: value, setTheme: onChange }) => {
  const themes = [
    {
      theme: "Default",
      primary: "#424446",
      secondary: "#1E1F23",
    },
    {
      theme: "Red",
      primary: "#440C0A",
      secondary: "#250706",
    },
    {
      theme: "Blue",
      primary: "#0F3047",
      secondary: "#091D2A",
    },
    {
      theme: "Purple",
      primary: "#210F47",
      secondary: "#130929",
    },
    {
      theme: "Green",
      primary: "#0F4740",
      secondary: "#082421",
    },
    {
      theme: "Brown",
      primary: "#4E380E",
      secondary: "#261B07",
    },
  ];

  return (
    <InputContainer className="relative" label="Theme">
      <Menu>
        <Menu.Button
          className={getClassNames(
            "flex items-center gap-x-2 justify-between w-full h-full",
            "bg-white4 border-0  text-left"
          )}
        >
          <div className="flex items-center gap-x-2">
            <div
              className={getClassNames("w-6 h-6 rounded-full")}
              style={{ backgroundColor: value.primary }}
            />
            <span className="text-body-black text-base leading-5 font-semibold capitalize">
              {value.theme}
            </span>
          </div>

          <span className="pointer-events-none mt-1">
            <CaretIcon />
          </span>
        </Menu.Button>

        <Menu.Items
          className={getClassNames(
            "absolute top-12 -left-1 z-10 bg-white shadow-lg p-4 pt-6 min-w-[20rem] rounded"
          )}
        >
          <span className="text-body-black text-base1">
            Select a theme
          </span>
          <div className="grid grid-cols-2 gap-y-4 gap-x-2 mt-4 mb-10">
            {themes?.map((option) => (
              <Menu.Item
                key={option.theme}
                onClick={() => onChange(option)}
                value={option.theme}
              >
                {({ active }) => (
                  <div>
                    <ThemeSample
                      primary={option.primary}
                      secondary={option.secondary}
                      border={active || value.theme === option.theme}
                    />
                    <div className="relative">
                      <div className="absolute -top-9 left-3 flex items-center justify-between w-full pr-6">
                        <span className="text-body-black ">{option.theme}</span>
                        <RadioButton
                          active={active || value.theme === option.theme}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Menu>
    </InputContainer>
  );
};

export default ThemeSelect;
