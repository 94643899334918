import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

// Context
import { authContext } from '@context/auth';

// Helpers
// import { axiosInstance } from "@utils/baseAxios";
import { getClassNames } from '@utils/helpers';

// Components
import { Button } from '@components/button';
import BackArrow from './BackArrow';

// Assets
import GroupInitial from '@pages/groups/components/groupInitial';
import { Avatar } from './avatarStack';

interface TopbarProps {
  title: string;
  buttonAction?: () => void;
  buttonConfig?: {
    title: string;
    color?: 'light' | 'dark' | 'dark2' | 'grey' | 'dark3';
  } | null;
  showBackButton?: boolean;
  showExtras?: boolean;
  showTitleTag?: boolean;
  options?: React.ReactNode;
  backButtonAction?: () => void;
  color?: string;
  className?: string;
  children?: React.ReactNode;
}

const Topbar: React.FC<TopbarProps> = ({
  title,
  buttonAction,
  buttonConfig = null,
  showBackButton = false,
  showExtras = true,
  showTitleTag,
  options,
  backButtonAction,
  color = '',
  className = '',
  children,
}) => {
  const { logout } = useContext(authContext);
  // const [userProfile, ] = useState({});
  // const [theme, setTheme] = useState(0);

  // const themes = ["", "red", "blue", "light"];
  // const switchTheme = () => {
  //   const htmlEl = document.getElementsByTagName("html")[0];
  //   htmlEl.setAttribute("data-theme", themes[theme + 1]);
  //   setTheme((prev) => (prev + 1) % 4);
  // };
  const navigate = useNavigate();
  const colors: Record<string, string> = {
    '': 'bg-white2',
    white: 'bg-white',
  };

  return (
    <div
      className={getClassNames(
        'w-full py-[0.62rem] px-8 flex border-b-grey h-[62px]',
        colors[color],
        className
      )}
    >
      <div className="flex items-center gap-x-2">
        {showBackButton ? (
          <BackArrow onClick={backButtonAction ? backButtonAction : () => navigate(-1)} />
        ) : null}
        {showTitleTag ? <GroupInitial /> : null}
        <h4 className="header4">{title}</h4>
        {options}
      </div>
      {children}
      <div
        className={getClassNames('flex items-center gap-x-4', !children ? 'ml-auto' : '')}
      >
        {buttonConfig && (
          <Button
            title={buttonConfig.title}
            category="1"
            color={buttonConfig.color || 'light'}
            onClick={buttonAction}
          />
        )}
        {/* <div
          className="theme-switch rounded-full w-10 h-10 bg-primary cursor-pointer border border-stroke"
          onClick={() => switchTheme()}
        ></div> */}
        {showExtras && (
          <>
            <button onClick={() => logout()} title="Logout">
              <Avatar title="Z" />
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default Topbar;
