import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Utils
import { getClassNames } from "@utils/helpers";
import { editProject, publishModalHandler } from "@redux/features/projects";

// Components
import ActionButtons from "../components/actionButtons";
import PublishModal from "./components/publishModal";
import PublishSuccess from "./components/publishSuccess";

// Assets
import { CheckButton } from "@components/RadioButton";
import DropDown from "@components/dropdown";
import { InputContainer } from "@components/input";
import { IRootState } from "@/redux/store";
import {
  SHOPPER_OPTION,
  ShopperOptions,
  executeOptions,
  shopperTypeMap,
  triggerOptions,
} from "./components/shopperExperienceOptions";
import { config_type } from "@/utils/constants";
import DemoGuidePopover from "@/components/DemoGuidePopover";
import { DemoGuidePopoverContext } from "@/context/demoGuidePopover";

type ShopperExperienceProps = {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setStage: React.Dispatch<React.SetStateAction<number>>;
};

const ShopperExperience: React.FC<ShopperExperienceProps> = ({
  step,
  setStage,
}) => {
  const dispatch = useDispatch();
  const { activeProject, trustedShopperConfig } = useSelector(
    (state: IRootState) => state.projectSlice
  );

  const { popUp20, setPopUp19, setPopUp20, setPopUp21 } = useContext(
    DemoGuidePopoverContext
  );

  const [riskType, setRiskType] = useState<config_type>("LOW_RISK");

  const [methodOfReturnLowRisk, setMethodOfReturnLowRisk] = useState<string>();
  const [triggerValueLowRisk, setTriggerValueLowRisk] = useState({
    label: "Received by logistics",
    value: "Received by logistics",
  });
  const [executeValueLowRisk, setExecuteValueLowRisk] = useState({
    label: "Immediately",
    value: "Immediately",
  });
  const [dayLowRisk, setDaysLowRisk] = useState<{
    label: string | number;
    value: string | number;
  }>({ label: "14", value: "14" });

  // Medium Risk Configs
  const [methodOfReturnMediumRisk, setMethodOfReturnMediumRisk] =
    useState<string>();
  const [triggerValueMediumRisk, setTriggerValueMediumRisk] = useState({
    label: "Received in warehouse",
    value: "Received in warehouse",
  });
  const [executeValueMediumRisk, setExecuteValueMediumRisk] = useState({
    label: "Immediately",
    value: "Immediately",
  });
  const [dayMediumRisk, setDaysMediumRisk] = useState<{
    label: string | number;
    value: string | number;
  }>({ label: "14", value: "14" });

  // HIgh Risk Configs
  const [methodOfReturnHighRisk, setMethodOfReturnHighRisk] =
    useState<string>();
  const [triggerValueHighRisk, setTriggerValueHighRisk] = useState({
    label: "Approved for refund",
    value: "Approved for refund",
  });
  const [executeValueHighRisk, setExecuteValueHighRisk] = useState({
    label: "Immediately",
    value: "Immediately",
  });
  const [dayHighRisk, setDaysHighRisk] = useState<{
    label: string | number;
    value: string | number;
  }>({ label: "14", value: "14" });

  const convertOptionsToArray = (options: ShopperOptions) => {
    const result: { name: SHOPPER_OPTION; enabled: boolean }[] = [];
    for (const [key, value] of Object.entries(options)) {
      result.push({ name: key as SHOPPER_OPTION, enabled: value });
    }
    return result;
  };
  const [trustedShopperPaymentOptions, setTrustedShopperPaymentOptions] =
    useState<{ name: SHOPPER_OPTION; enabled: boolean }[]>([]);
  const [mediumRiskShopperPaymentOptions, setMediumRiskShopperPaymentOptions] =
    useState<{ name: SHOPPER_OPTION; enabled: boolean }[]>([]);
  const [highRiskShopperPaymentOptions, setHighRiskShopperPaymentOptions] =
    useState<{ name: SHOPPER_OPTION; enabled: boolean }[]>([]);

  useEffect(() => {
    if (!activeProject) return;
    setTrustedShopperPaymentOptions(
      convertOptionsToArray(activeProject?.low_risk_shopper_options)
    );
    setMediumRiskShopperPaymentOptions(
      convertOptionsToArray(activeProject?.medium_risk_shopper_options)
    );
    setHighRiskShopperPaymentOptions(
      convertOptionsToArray(activeProject?.high_risk_shopper_options)
    );
    setExecuteValueLowRisk({
      label: activeProject?.low_risk_refund_details?.refund_execution,
      value: activeProject?.low_risk_refund_details?.refund_execution,
    });
    setTriggerValueLowRisk({
      label: activeProject?.low_risk_refund_details?.refund_trigger,
      value: activeProject?.low_risk_refund_details?.refund_trigger,
    });
    setDaysLowRisk({
      label: activeProject?.low_risk_refund_details?.days_to_refund,
      value: activeProject?.low_risk_refund_details?.days_to_refund,
    });
    setMethodOfReturnLowRisk(
      activeProject?.low_risk_refund_details?.refund_mode
    );
    setExecuteValueMediumRisk({
      label: activeProject?.medium_risk_refund_details?.refund_execution,
      value: activeProject?.medium_risk_refund_details?.refund_execution,
    });
    setTriggerValueMediumRisk({
      label: activeProject?.medium_risk_refund_details?.refund_trigger,
      value: activeProject?.medium_risk_refund_details?.refund_trigger,
    });
    setDaysMediumRisk({
      label: activeProject?.medium_risk_refund_details?.days_to_refund,
      value: activeProject?.medium_risk_refund_details?.days_to_refund,
    });
    setMethodOfReturnMediumRisk(
      activeProject?.medium_risk_refund_details?.refund_mode
    );
    setExecuteValueHighRisk({
      label: activeProject?.high_risk_refund_details?.refund_execution,
      value: activeProject?.high_risk_refund_details?.refund_execution,
    });
    setTriggerValueHighRisk({
      label: activeProject?.high_risk_refund_details?.refund_trigger,
      value: activeProject?.high_risk_refund_details?.refund_trigger,
    });
    setDaysHighRisk({
      label: activeProject?.high_risk_refund_details?.days_to_refund,
      value: activeProject?.high_risk_refund_details?.days_to_refund,
    });
    setMethodOfReturnHighRisk(
      activeProject?.high_risk_refund_details?.refund_mode
    );
  }, [activeProject]);

  const riskTypePaymentOptions: Record<
    config_type,
    { name: SHOPPER_OPTION; enabled: boolean }[]
  > = {
    LOW_RISK: trustedShopperPaymentOptions,
    MEDIUM_RISK: mediumRiskShopperPaymentOptions,
    HIGH_RISK: highRiskShopperPaymentOptions,
  };
  const riskTypeRefundOptions = {
    LOW_RISK: {
      methodOfReturn: methodOfReturnLowRisk,
      setMethodOfReturn: setMethodOfReturnLowRisk,
      triggerValue: triggerValueLowRisk,
      setTriggerValue: setTriggerValueLowRisk,
      executeValue: executeValueLowRisk,
      setExecuteValue: setExecuteValueLowRisk,
      days: dayLowRisk,
      setDays: setDaysLowRisk,
    },
    MEDIUM_RISK: {
      methodOfReturn: methodOfReturnMediumRisk,
      setMethodOfReturn: setMethodOfReturnMediumRisk,
      triggerValue: triggerValueMediumRisk,
      setTriggerValue: setTriggerValueMediumRisk,
      executeValue: executeValueMediumRisk,
      setExecuteValue: setExecuteValueMediumRisk,
      days: dayMediumRisk,
      setDays: setDaysMediumRisk,
    },
    HIGH_RISK: {
      methodOfReturn: methodOfReturnHighRisk,
      setMethodOfReturn: setMethodOfReturnHighRisk,
      triggerValue: triggerValueHighRisk,
      setTriggerValue: setTriggerValueHighRisk,
      executeValue: executeValueHighRisk,
      setExecuteValue: setExecuteValueHighRisk,
      days: dayHighRisk,
      setDays: setDaysHighRisk,
    },
  };

  const handlePaymentSelect = (name: SHOPPER_OPTION) => {
    const temp = [...riskTypePaymentOptions[riskType]];

    // Find the index of the object with the matching name
    const index = temp.findIndex((item) => item.name === name);

    if (index !== -1) {
      // Update the object in the array
      temp[index] = { ...temp[index], enabled: !temp[index].enabled };

      if (riskType === "LOW_RISK") {
        setTrustedShopperPaymentOptions(temp);
      } else if (riskType === "MEDIUM_RISK") {
        setMediumRiskShopperPaymentOptions(temp);
      } else {
        setHighRiskShopperPaymentOptions(temp);
      }
    }
  };

  const getStandardPaymentOptions = (
    riskType: config_type,
    standard = true
  ) => {
    const nonStandardOptions = ["Pay by installments", "Pay after delivery"];
    if (standard) {
      return riskTypePaymentOptions[riskType].filter(
        (item) => !nonStandardOptions.includes(item.name)
      );
    } else {
      return riskTypePaymentOptions[riskType].filter((item) =>
        nonStandardOptions.includes(item.name)
      );
    }
  };

  const onUndo = () => {
    if (!activeProject) return;
    if (riskType === "HIGH_RISK") {
      setRiskType("MEDIUM_RISK");
      return;
    } else if (riskType === "MEDIUM_RISK") {
      setRiskType("LOW_RISK");
      return;
    }
  };

  const onContinue = async () => {
    if (!activeProject) return;
    if (riskType === "LOW_RISK") {
      setRiskType("MEDIUM_RISK");
      return;
    } else if (riskType === "MEDIUM_RISK") {
      setRiskType("HIGH_RISK");
      return;
    }
    const config_details = {
      high_risk_shopper_options: {
        "Pay after delivery": false,
        "Pay by installments": false,
        "Debit/Credit card": false,
        "Pay by Bank": false,
        "Apple Pay": false,
        "Google Pay": false,
      },
      medium_risk_shopper_options: {
        "Pay after delivery": false,
        "Pay by installments": false,
        "Debit/Credit card": false,
        "Pay by Bank": false,
        "Apple Pay": false,
        "Google Pay": false,
      },
      low_risk_shopper_options: {
        "Pay after delivery": false,
        "Pay by installments": false,
        "Debit/Credit card": false,
        "Pay by Bank": false,
        "Apple Pay": false,
        "Google Pay": false,
      },
      low_risk_refund_details: {},
      medium_risk_refund_details: {},
      high_risk_refund_details: {},
    };

    trustedShopperPaymentOptions.forEach(
      (option: { name: SHOPPER_OPTION; enabled: boolean }) => {
        config_details.low_risk_shopper_options[option.name] = option.enabled;
      }
    );

    mediumRiskShopperPaymentOptions.forEach((option) => {
      config_details.medium_risk_shopper_options[option.name] = option.enabled;
    });

    highRiskShopperPaymentOptions.forEach((option) => {
      config_details.high_risk_shopper_options[option.name] = option.enabled;
    });

    type refund_details = {
      refund_mode?: string;
      refund_trigger?: string;
      refund_execution?: string;
      days_to_refund?: string | number;
    };

    const low_risk_refund_details: refund_details = {
      refund_mode: methodOfReturnLowRisk,
      refund_trigger: triggerValueLowRisk.value,
      refund_execution: executeValueLowRisk.value,
      days_to_refund: dayLowRisk.value,
    };
    if (low_risk_refund_details.refund_execution === "Immediately") {
      delete low_risk_refund_details.days_to_refund;
    }
    config_details.low_risk_refund_details = low_risk_refund_details;
    const medium_risk_refund_details: refund_details = {
      refund_mode: methodOfReturnMediumRisk,
      refund_trigger: triggerValueMediumRisk.value,
      refund_execution: executeValueMediumRisk.value,
      days_to_refund: dayMediumRisk.value,
    };
    if (medium_risk_refund_details.refund_execution === "Immediately") {
      delete medium_risk_refund_details.days_to_refund;
    }
    config_details.medium_risk_refund_details = medium_risk_refund_details;
    const high_risk_refund_details: refund_details = {
      refund_mode: methodOfReturnHighRisk,
      refund_trigger: triggerValueHighRisk.value,
      refund_execution: executeValueHighRisk.value,
      days_to_refund: dayHighRisk.value,
    };
    if (high_risk_refund_details.refund_execution === "Immediately") {
      delete high_risk_refund_details.days_to_refund;
    }
    config_details.high_risk_refund_details = high_risk_refund_details;

    await dispatch(
      editProject({
        id: activeProject?.project_id,
        data: {
          config_details,
        },
      }) as any
    )
      .unwrap()
      .then(() => {
        dispatch(publishModalHandler(true));
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const [transitionLoading, setTransitionLoading] = useState(false);
  useEffect(() => {
    setTransitionLoading(true);
    setTimeout(() => {
      setTransitionLoading(false);
    }, 500);
  }, [riskType]);

  const useCase = localStorage.getItem("product") || "PAYMENT_PERSONALIZATION";

  return step < 1 ? null : (
    <>
      <PublishModal />
      <PublishSuccess />
      <div className="mb-8 text-lg" id="target20">
        <DemoGuidePopover
          title="Define experiences by segment"
          el={() => document.getElementById("target20")}
          topOffset={70}
          leftOffset={140}
          arrowPosition="top"
          maxWidth="max-w-[355px]"
          open={popUp20}
          setOpen={setPopUp20}
          setNext={setPopUp21}
          setPrev={setPopUp19}
        >
          <p className="text-grey-content-tertiary mt-3">
            For each segment defined in step 2, define the experiences consumers
            should receive. <br />
            <br />
            Use this to enhance consumer experience, streamline
            checkout, fast track refunds and prevent fraud
          </p>
        </DemoGuidePopover>

        <div className="flex gap-x-4 bg-white">
          {trustedShopperConfig?.map((config: any) => (
            <div
              className={getClassNames(
                "flex items-center justify-between gap-x-2 rounded-lg py-3 px-4 border",
                "border-grey-stroke-primary cursor-pointer",
                riskType === config.config_type
                  ? "bg-grey-stroke-tertiary"
                  : "bg-white"
              )}
              onClick={() => {
                setRiskType(config.config_type);
              }}
              key={config.config_type}
            >
              <div className="flex items-baseline gap-x-0.5 self-center bg-grey-stroke-primary p-2 rounded">
                <span
                  className={getClassNames(
                    shopperTypeMap[config?.config_type]?.classNm
                  )}
                >
                  {parseInt(config.score)}
                </span>
                {shopperTypeMap[config?.config_type].icon}
              </div>
              <span
                className={getClassNames(
                  riskType === config.config_type
                    ? "text-black"
                    : "text-grey-content-tertiary"
                )}
              >
                {shopperTypeMap[config?.config_type].title}
              </span>
            </div>
          ))}
        </div>
      </div>
      <div className="overflow-scroll flex-1 h-full mb-40">
        {useCase === "PAYMENT_PERSONALIZATION" && (
          <>
            <h6 className="header6 text-body-black">
              Checkout and Payments
            </h6>
            <div className="mt-3 border border-grey-stroke-primary bg-white p-10 rounded-md max-w-[775px]">
              <h6
                className={getClassNames(
                  "text-base",
                  transitionLoading
                    ? "text-stroke-secondary bg-stroke-secondary w-fit rounded-md"
                    : "text-desc-grey"
                )}
              >
                Standard payment options
              </h6>
              <div className="mt-2 flex gap-2 max-w-[659px] flex-wrap">
                {getStandardPaymentOptions(riskType)?.map((item) => (
                  <div
                    className={getClassNames(
                      "flex items-center justify-between bg-white2 p-4  gap-x-10 cursor-pointer rounded",
                      item.enabled ? "border border-grey-stroke-primary" : ""
                    )}
                    onClick={() => handlePaymentSelect(item.name)}
                  >
                    <span
                      className={getClassNames(
                        transitionLoading
                          ? "animate-pulse bg-stroke-secondary text-stroke-secondary rounded-md"
                          : "",
                        item.enabled ? "text-body-black" : ""
                      )}
                    >
                      {item.name}
                    </span>
                    {transitionLoading ? (
                      <div
                        className={getClassNames(
                          "h-[18px] w-[18px] min-h-[18px] min-w-[18px] rounded-full bg-stroke-secondary",
                          transitionLoading && "animate-pulse"
                        )}
                      />
                    ) : (
                      <CheckButton active={item.enabled} />
                    )}
                  </div>
                ))}
              </div>

              <p
                className={getClassNames(
                  " max-w-[600px] mt-4",
                  transitionLoading
                    ? "text-stroke-secondary bg-stroke-secondary w-fit rounded-md"
                    : "text-grey-content-tertiary"
                )}
              >
                Standard instant payment options available to all consumers.
              </p>

              {/* keep this here */}
              <h6
                className={getClassNames(
                  "mt-8 text-base",
                  transitionLoading
                    ? "text-stroke-secondary bg-stroke-secondary w-fit rounded-md"
                    : "text-desc-grey"
                )}
              >
                Additional payment options
              </h6>
              <div className="mt-2 flex gap-2 max-w-[659px] flex-wrap">
                {getStandardPaymentOptions(riskType, false)?.map((item) => (
                  <div
                    className={getClassNames(
                      "flex items-center justify-between bg-white2 p-4 gap-x-10 cursor-pointer rounded",
                      item.enabled ? "border border-grey-stroke-primary" : ""
                    )}
                    onClick={() => handlePaymentSelect(item.name)}
                  >
                    <span
                      className={getClassNames(
                        transitionLoading
                          ? "animate-pulse bg-stroke-secondary text-stroke-secondary rounded-md"
                          : "",
                        item.enabled ? "text-body-black" : ""
                      )}
                    >
                      {item.name}
                    </span>
                    {transitionLoading ? (
                      <div
                        className={getClassNames(
                          "h-[18px] w-[18px] min-h-[18px] min-w-[18px] rounded-full bg-stroke-secondary",
                          transitionLoading && "animate-pulse"
                        )}
                      />
                    ) : (
                      <CheckButton active={item.enabled} />
                    )}
                  </div>
                ))}
              </div>
              <p
                className={getClassNames(
                  " max-w-[600px] mt-4",
                  transitionLoading
                    ? "text-stroke-secondary bg-stroke-secondary w-fit rounded-md"
                    : "text-grey-content-tertiary"
                )}
              >
                Trusted consumers can be trusted to pay later.
              </p>
            </div>
          </>
        )}

        <h6 className={getClassNames("header6 text-body-black", useCase === "PAYMENT_PERSONALIZATION" ? "mt-8" : "")}>Returns and Refunds</h6>
        <div className="mt-3 mb-20 border border-grey-stroke-primary bg-white p-10 rounded-md max-w-[775px]">
          <h6
            className={getClassNames(
              "text-base",
              transitionLoading
                ? "text-stroke-secondary bg-stroke-secondary w-fit rounded-md"
                : "text-desc-grey"
            )}
          >
            Method of Return
          </h6>
          <div className="mt-2 flex gap-2">
            <button
              className={getClassNames(
                "flex items-center justify-between p-4 border gap-x-10",
                riskTypeRefundOptions[riskType]?.methodOfReturn ===
                  "Instant refund"
                  ? "border-grey-stroke-primary bg-white2 text-body-black"
                  : "border-grey-stroke-tertiary bg-white text-grey-content-secondary"
              )}
              onClick={() =>
                riskTypeRefundOptions[riskType]?.setMethodOfReturn(
                  "Instant refund"
                )
              }
            >
              <span
                className={getClassNames(
                  transitionLoading
                    ? "animate-pulse bg-stroke-secondary text-stroke-secondary rounded-md"
                    : ""
                )}
              >
                Instant refund
              </span>
              {transitionLoading ? (
                <div
                  className={getClassNames(
                    "h-[18px] w-[18px] min-h-[18px] min-w-[18px] rounded-full bg-stroke-secondary",
                    transitionLoading && "animate-pulse"
                  )}
                />
              ) : (
                <CheckButton
                  active={
                    riskTypeRefundOptions[riskType]?.methodOfReturn ===
                    "Instant refund"
                  }
                />
              )}
            </button>
            <button
              className={getClassNames(
                "flex items-center justify-between p-4 border gap-x-10",
                riskTypeRefundOptions[riskType]?.methodOfReturn ===
                  "Refund after return"
                  ? "border-grey-stroke-primary bg-white2 text-body-black"
                  : "border-grey-stroke-tertiary bg-white text-grey-content-secondary"
              )}
              onClick={() =>
                riskTypeRefundOptions[riskType]?.setMethodOfReturn(
                  "Refund after return"
                )
              }
            >
              <span
                className={getClassNames(
                  transitionLoading
                    ? "animate-pulse bg-stroke-secondary text-stroke-secondary rounded-md"
                    : ""
                )}
              >
                Refund after return
              </span>
              {transitionLoading ? (
                <div
                  className={getClassNames(
                    "h-[18px] w-[18px] min-h-[18px] min-w-[18px] rounded-full bg-stroke-secondary",
                    transitionLoading && "animate-pulse"
                  )}
                />
              ) : (
                <CheckButton
                  active={
                    riskTypeRefundOptions[riskType]?.methodOfReturn ===
                    "Refund after return"
                  }
                />
              )}
            </button>
          </div>

          <p
            className={getClassNames(
              " max-w-[600px] mt-4",
              transitionLoading
                ? "text-stroke-secondary bg-stroke-secondary w-fit rounded"
                : "text-grey-content-tertiary"
            )}
          >
            {shopperTypeMap[riskType]?.description}
          </p>
          <div className="flex flex-col gap-y-6 mt-6">
            <DropDown
              label="Trigger"
              options={triggerOptions}
              selected={riskTypeRefundOptions[riskType]?.triggerValue}
              onChange={riskTypeRefundOptions[riskType]?.setTriggerValue}
            />
            <div>
              <DropDown
                label="Execute"
                options={executeOptions}
                selected={riskTypeRefundOptions[riskType]?.executeValue}
                onChange={riskTypeRefundOptions[riskType]?.setExecuteValue}
              />
              {riskTypeRefundOptions[riskType]?.executeValue.value ===
                "After" && (
                <div className="grid grid-cols-2 gap-x-2 items-baseline">
                  <DropDown
                    options={[
                      { label: "1", value: "1" },
                      { label: "2", value: "2" },
                      { label: "3", value: "3" },
                      { label: "4", value: "4" },
                      { label: "5", value: "5" },
                      { label: "6", value: "6" },
                      { label: "7", value: "7" },
                      { label: "8", value: "8" },
                      { label: "9", value: "9" },
                      { label: "10", value: "10" },
                      { label: "11", value: "11" },
                      { label: "12", value: "12" },
                      { label: "13", value: "13" },
                      { label: "14", value: "14" },
                    ]}
                    selected={riskTypeRefundOptions[riskType]?.days}
                    onChange={riskTypeRefundOptions[riskType]?.setDays}
                    position="-bottom-10"
                  />
                  <InputContainer className="md:py-3">
                    Day
                    <span
                      className={getClassNames(
                        riskTypeRefundOptions[riskType]?.days.value === 1
                          ? "text-grey-content-secondary"
                          : ""
                      )}
                    >
                      s
                    </span>
                  </InputContainer>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <ActionButtons {...{ setStage, onContinue, onUndo }} />
    </>
  );
};

export default ShopperExperience;
