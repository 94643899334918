import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '@/redux/store';

// Hooks
import { Config, getProjectById } from '@redux/features/projects';

// Utils
import { ShopperScoreOrder, getClassNames, getLocalStorageData } from '@utils/helpers';

// Components
import { Button } from '@components/button';

// Assets
import { ReactComponent as ArrowIcon } from '@icons/arrow.svg';
import CaretIcon from '@icons/CaretSm';
import { ReactComponent as GearIcon } from '@icons/gear.svg';
import { ReactComponent as ScoreIcon } from '@icons/score.svg';

type ShopperConfigProps = {
  collapse: boolean;
  setCollapse: React.Dispatch<React.SetStateAction<boolean>>;
};

const ShopperConfig: React.FC<ShopperConfigProps> = ({ collapse, setCollapse }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { activeProject, selectedProject } = useSelector(
    (state: IRootState) => state.projectSlice
  );
  const scoreIcons = [
    <CaretIcon color="#02BD8E" width={5} height={4} className="" />,
    <CaretIcon color="#EFB604" width={5} height={4} className="rotate-180" />,
    <CaretIcon color="#E5374C" className="rotate-180" />,
  ];

  useEffect(() => {
    if (selectedProject) dispatch(getProjectById(selectedProject.id) as any);
  }, [dispatch, selectedProject]);

  const trustedShopperConfigCaption = {
    LOW_RISK: 'Trusted Shopper',
    MEDIUM_RISK: 'Medium Risk Shopper',
    HIGH_RISK: 'High Risk Shopper',
  };

  const shopper_config_local_score = getLocalStorageData(`project_${activeProject?.id}`);
  const shopper_config_score: Config[] = shopper_config_local_score?.config
    ? shopper_config_local_score?.config
    : activeProject?.shopper_trust_index_configs;

  const filtered_shopper_score = shopper_config_score?.filter(
    (config) => config.environment === activeProject?.environment
  );
  const shopperConfigScore = ShopperScoreOrder(filtered_shopper_score);

  return (
    <div
      className={getClassNames(
        ' bg-white2 border-grey-stroke-primary text-grey-content-secondary rounded-ss-md',
        'overflow-auto mb-20',
        collapse
          ? 'max-w-[0px] max-h-[0px] overflow-hidden'
          : 'h-full w-full max-w-[312px] max-h-full border-r'
      )}
    >
      <div
        className={getClassNames(
          'flex items-center z-[100]',
          collapse
            ? 'hidden absolute justify-center bg-white2 rounded-tl-lg border-r border-b border-grey-stroke-primary w-[58px] h-12'
            : 'justify-between px-6 py-4'
        )}
      >
        <ScoreIcon />
        <ArrowIcon
          className={getClassNames(
            'cursor-pointer transition-all duration-300',
            collapse ? '-rotate-90 ml-1' : 'rotate-90'
          )}
          color="#B1B2B6"
          onClick={() => setCollapse((prev) => !prev)}
        />
      </div>
      <div className="px-6">
        <div className="flex justify-between items-center">
          <h6 className="header6 text-body-black">Shopper Trust Index</h6>
          <GearIcon className="rotate-90" color="#B1B2B6" />
        </div>
        <p className="text-sm1 mt-1 text-grey-content-tertiary">
          Your organisation’s Trusted Shopper settings
        </p>
        <div className="flex flex-col rounded bg-grey-stroke-tertiary border border-grey-stroke-primary mt-4">
          {activeProject?.trusted_shopper_score_configs
            ?.filter((config: Config) => config.environment === activeProject.environment)
            .map(({ id, score, config_type }, i) => (
              <div
                key={id}
                className={getClassNames(
                  'flex items-center justify-between p-1 border-b border-grey-stroke-primary',
                  score === '0' ? 'opacity-50' : ''
                )}
              >
                <span>{trustedShopperConfigCaption[config_type]}</span>
                <div
                  className={getClassNames(
                    'flex items-baseline justify-center gap-x-0.5 self-center bg-grey-stroke-primary p-1 rounded min-w-[30px] text-center'
                  )}
                >
                  <span
                    className={getClassNames(
                      ['text-risk-green', 'text-trud-orange', 'text-risk-red'][i % 3],
                      'text-center'
                    )}
                  >
                    {score === '0' ? '  -' : parseInt(score)}
                  </span>
                  {parseInt(score) > 0 && scoreIcons[i]}
                </div>
              </div>
            ))}
        </div>
        <div className="flex justify-between items-center mt-8">
          <span className="text-grey-content-secondary">
            Shopper Trust Index Configuration
          </span>
          <ArrowIcon className="rotate-0" color="#B1B2B6" />
        </div>
        <div className="flex flex-col bg-white rounded border border-grey-stroke-primary mt-2">
          {shopperConfigScore.map(({ id, name, score }) => (
            <div
              key={id}
              className="flex items-center justify-between gap-x-1 p-2 border-b border-grey-stroke-primary"
            >
              <span className="leading-lg1 max-w-[248px]">{name}</span>
              <div className="bg-grey-stroke-primary p-2 rounded-sm max-h-7 max-w-7 w-7 flex items-center justify-center">
                <span className="text-sm text-body-black">
                  {score === '0' ? ' -' : parseInt(score)}
                </span>
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-between items-center mt-2">
          <span className="text-sm1 leading-base1 text-grey-content-tertiary">
            Visit the product configuration page to make changes.
          </span>
          <Button
            title="Edit"
            color="light"
            className="pt-1 pb-1 border-0 text-body-black text-sm1"
            onClick={() => navigate('/projects/edit')}
          />
        </div>
      </div>
    </div>
  );
};

export default ShopperConfig;
