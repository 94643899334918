import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Redux
import { publishModalHandler } from '@redux/features/projects';
import { IRootState } from '@/redux/store';

// Utils
// import { SHOPPER_TRUST_INDEX } from "@utils/constants";

// Components
import { Avatar } from '@components/avatarStack';
import { Button } from '@components/button';
import { ProjectEnvIndicator } from '@components/ProjectStateToggle';
import ToolTip from '@components/ToolTip';
import Topbar from '@components/topbar';

// Assets
import { ReactComponent as GearIcon } from '@icons/gear.svg';
import PublishModalCR from '../checkout-and-refund/components/publishModal';
import PublishSuccessCR from '../checkout-and-refund/components/publishSuccess';
import PublishModal from '../shopper-trust-index/components/publishModal';
import { authContext } from '@/context/auth';
import PublishSuccess from '../shopper-trust-index/components/publishSuccess';

type ProjectTopBarProps = {
  step?: number;
  stage?: number;
  setStep?: React.Dispatch<React.SetStateAction<number>>;
  setStage?: React.Dispatch<React.SetStateAction<number>>;
  title?: string;
  showPublishButton?: boolean;
  backButtonAction?: () => void;
};

const ProjectTopBar: React.FC<ProjectTopBarProps> = ({
  step,
  stage,
  setStep,
  setStage,
  title,
  showPublishButton = true,
  backButtonAction,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { logout } = useContext(authContext);
  const { activeProject } = useSelector(
    (state: IRootState) => state.projectSlice,
  );

  const newBackButtonAction = () => {
    if (!setStage || !setStep) return;
    if (stage === 0) {
      if (step === 0) {
        navigate(-1);
      } else {
        setStep((prev) => prev - 1);
      }
    } else if (stage === 1) {
      state == 'Edit'
        ? navigate('/projects/edit')
        : navigate('/projects/manage');
    } else {
      setStage((prev) => prev - 1);
    }
  };
  const useCase = localStorage.getItem('product') || 'PAYMENT_PERSONALIZATION';

  return (
    <>
      <Topbar
        title={title || (activeProject && activeProject?.name) || ''}
        showBackButton
        backButtonAction={backButtonAction || newBackButtonAction}
        color='white'
        className='border-b'
        showExtras={false}
      >
        <div className='flex items-center gap-x-4 ml-auto mr-4 w-fit'>
          <div className='flex items-center gap-x-2'>
            {/* <Button
              title="Save"
              category="2"
              color="light"
              className="w-fit"
              onClick={() => dispatch(saveModalHandler(true))}
            /> */}
            {showPublishButton && (
              <Button
                title='Publish'
                category='2'
                color='dark'
                className='w-fit'
                onClick={() => dispatch(publishModalHandler(true))}
              />
            )}
          </div>
          <div className='flex items-center gap-x-2 mr-2'>
            <ProjectEnvIndicator />
            <div className='relative group'>
              <GearIcon
                onClick={() => navigate('/projects/edit')}
                className='cursor-pointer'
              />
              <ToolTip
                info='Project settings'
                className='right-0 top-6 whitespace-nowrap'
              />
            </div>
          </div>
          <button onClick={() => logout()} title='Logout'>
            <Avatar title='Z' />
          </button>
        </div>
      </Topbar>
      {useCase === 'SHOPPER_TRUST_INDEX' ? (
        <>
          <PublishModal />
          <PublishSuccess />
          {/* <SaveModal /> */}
          {/* <SaveSuccess /> */}
        </>
      ) : (
        <>
          <PublishModalCR />
          <PublishSuccessCR />
          {/* <SaveModalCR /> */}
          {/* <SaveSuccessCR /> */}
        </>
      )}
    </>
  );
};

export default ProjectTopBar;
