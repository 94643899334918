import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useInView } from 'react-intersection-observer';

// Utils
import { getClassNames } from '@utils/helpers';

// Redux
import { Project, getProjects, setSelectedProject } from '@redux/features/projects';
// import { IRootState } from "@/redux/store";

// Context
import { DemoGuidePopoverContext } from '@/context/demoGuidePopover';

// Hooks
import useScreenSize from '@hooks/useScreenSize';

// Components
import DemoGuidePopover from '@/components/DemoGuidePopover';
import DropDown from '@components/dropdown';
// import ProjectStateToggle from '@components/ProjectStateToggle';
import ShoppersCharts from './components/shoppersCharts';
import ShopperConfig from './components/shopperConfig';
import ShoppersList from './components/shoppersList';
import ShoppersListReturnsCopilot from './components/shoppersListReturnsCopilot';
import Topbar from '@components/topbar';
import { Tab } from '@headlessui/react';
import PartnerInfraStructure from './PartnerInfraStructure';
import PartnerNetwork from './PartnerNetwork';
import PartnerPerformance from './PartnerPerformance';
import MonthDropdown from './MonthDropdown';
import moment from 'moment';
import { Option } from '@/components/FormElement/types';

type DropDownProject = Project & {
  label: string;
  value?: string;
};
const Dashboard = () => {
  const { ref, inView } = useInView({
    threshold: 1,
  });
  const { height } = useScreenSize();
  const [collapse, setCollapse] = useState(false);
  const [selectedShopper, setSelectedShopper] = useState();
  const tabOptions = ['Operations', 'Network', 'Performance', 'Infrastructure'];
  const [selectedIndex, setSelectedIndex] = useState(0);
  // const { projects } = useSelector((state: IRootState) => state.projectSlice);
  const [project, setProject] = useState<DropDownProject>();
  // const [environment, setEnvironment] = useState('SANDBOX');
  const [selectedMonth, setSelectedMonth] = useState<Option>({
    label: moment().format(`MMM 'YY`),
    value: moment().format('YYYY-MM'),
  });

  const dispatch = useDispatch();

  const useCase = localStorage.getItem('product') ?? 'PAYMENT_PERSONALIZATION';
  useEffect(() => {
    dispatch(getProjects() as any).then(({ payload }: { payload: Project[] }) => {
      if (payload?.length > 0) {
        payload?.map((project: Project) => {
          if (project.products === useCase) {
            !localStorage.getItem('product') &&
              localStorage.setItem('product', project.products);
            setProject({ label: project.name, ...project });
            dispatch(setSelectedProject(project));
          }
          return 0;
        });
      }
    });
  }, [dispatch, useCase]);

  const handleProjectSelect = (prj: DropDownProject) => {
    setProject(prj);
    dispatch(setSelectedProject(prj));
    localStorage.setItem('product', prj.products);
  };

  const { popUp2, setPopUp2, setPopUp1, setPopUp3, showExploreDemo } = React.useContext(
    DemoGuidePopoverContext
  );

  return (
    <div
      className={getClassNames(
        'flex w-full flex-col h-screen bg-white overflow-hidden',
        showExploreDemo ? 'blur-sm' : ''
      )}
    >
      <Topbar title="" buttonConfig={null}>
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center">
            <DropDown
              options={[]}
              selected={{ label: project?.label || '', value: project?.id || '' }}
              onChange={handleProjectSelect}
              className="h-8 flex max-h-[32px] items-center justify-center"
              position="top-10 left-0"
              width="min-w-[231px]"
              size="py-2"
              style={{ backgroundColor: '#EDEDED' }}
            />
          </div>
          <div className="relative">
            <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
              <Tab.List className=" flex gap-4">
                {tabOptions?.map((tabTitle) => (
                  <Tab key={tabTitle}>
                    {({ selected }) => (
                      <div
                        className={getClassNames(
                          'px-4 py-2 border border-grey-stroke-primary rounded',
                          selected
                            ? 'text-trud-black bg-grey-stroke-primary'
                            : 'text-grey-content-secondary'
                        )}
                      >
                        {tabTitle}
                      </div>
                    )}
                  </Tab>
                ))}
              </Tab.List>
            </Tab.Group>
          </div>
          <div className="flex items-center mr-4">
            {/* <ProjectStateToggle
              className="rounded"
              value={environment}
              onChange={setEnvironment}
            /> */}
          </div>
        </div>
      </Topbar>
      <div className="relative flex-1 flex flex-col h-full overflow-auto px-8 py-7">
        <Tab.Group selectedIndex={selectedIndex}>
          <Tab.List className="hidden">
            <Tab></Tab>
            <Tab></Tab>
            <Tab></Tab>
            <Tab></Tab>
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel>
              <div className="flex items-center justify-between">
                <h6 className="header6 text-body-black">Overview</h6>
                <MonthDropdown
                  selectedMonth={selectedMonth}
                  setSelectedMonth={setSelectedMonth}
                />
              </div>
              <ShoppersCharts
                iref={ref as unknown as React.RefObject<HTMLDivElement>}
                selectedMonth={selectedMonth.label as string}
              />
              <div
                className={getClassNames(
                  !inView ? 'sticky -top-10 max-h-full' : '',
                  'relative'
                )}
                id="target2"
              >
                <DemoGuidePopover
                  title="1. Network analytics"
                  body="Overview of consumers in your network indicating your organisation’s  fraud profile."
                  el={() => document.getElementById('target2')}
                  topOffset={20}
                  leftOffset={0}
                  arrowPosition="top"
                  open={popUp2}
                  setOpen={setPopUp2}
                  setPrev={setPopUp1}
                  setNext={setPopUp3}
                />
                <h6 className="header6 text-body-black mt-6 relative">
                  {useCase === 'CHECKOUT_AND_REFUND_MANAGER'
                    ? 'Refunds Orchestrator'
                    : 'Trust Network'}
                </h6>
                <div
                  className={getClassNames(
                    'relative flex flex-1 border rounded-lg border-grey-stroke-primary bg-white mt-2'
                  )}
                  style={{ height: `${height - 96}px` }}
                >
                  {<ShopperConfig {...{ collapse, setCollapse }} />}
                  {useCase === 'CHECKOUT_AND_REFUND_MANAGER' ? (
                    <ShoppersListReturnsCopilot
                      {...{
                        project,
                        collapse,
                        setCollapse,
                        selectedShopper,
                        setSelectedShopper,
                      }}
                    />
                  ) : (
                    <ShoppersList
                      {...{
                        project,
                        collapse,
                        setCollapse,
                        selectedShopper,
                        setSelectedShopper,
                      }}
                    />
                  )}
                </div>
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <PartnerNetwork />
            </Tab.Panel>
            <Tab.Panel>
              <PartnerPerformance />
            </Tab.Panel>
            <Tab.Panel>
              <PartnerInfraStructure />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
};

export default Dashboard;
