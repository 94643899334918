import React from "react";
// import { useNavigate } from "react-router-dom";

// Components
import CustomPopover from "@components/customPopover";

// Assets
import { ReactComponent as DownIcon } from "@icons/arrow_down.svg";

// const colors = ["#5A66DA", "#EFB604", "#424446", "#40A4EC"];

interface AvatarProps {
  title: string;
  onClick?: () => void;
}

export const Avatar: React.FC<AvatarProps> = ({ title, onClick }) => {
  // const navigate = useNavigate();
  // const { team } = useSelector((state) => state.teamSlice);
  // const options = [
  //   { name: "Account", value: 0, onClick: () => navigate("/settings") },
  //   {
  //     name: "Settings",
  //     value: 1,
  //     onClick: () => navigate("/settings"),
  //   },
  //   {
  //     name: "Help & Support",
  //     value: 2,
  //   },
  //   {
  //     name: "Tell a friend",
  //     value: 3,
  //   },
  //   {
  //     name: "Logout",
  //     value: 4,
  //     onClick,
  //   },
  // ];

  const initial = localStorage.getItem("email") || "Z";

  return (
    <div className="relative z-10">
      <CustomPopover
        className="right-[0px] hidden"
        // handleChange={(c) => (options[c].onClick ? options[c].onClick : {})}
        placeholder={
          <div
            className="flex items-center gap-x-2"
            aria-roledescription="button"
          >
            <div
              className="rounded-full w-7 h-7 text-base1 leading-lg1 flex items-center justify-center text-white2 cursor-pointer capitalize bg-grey-content-tertiary"
            >
              <span className="mt-0.5">{initial[0] || title?.slice(0,1)}</span>
            </div>
            <DownIcon />
          </div>
        }
        // options={options}
      />
    </div>
  );
};

interface AvatarStackProps {
  size?: string;
  fontSize?: string;
  maxCount?: number;
}

const AvatarStack: React.FC<AvatarStackProps> = ({ size = "26px", fontSize = "1rem", maxCount = 4 }) => {
  // const { team } = useSelector((state: IRootState) => state.projectSlice);
  // const members = team?.data?.members || [];
  // let data = members;
  // if (members?.length > maxCount) {
  //   data = members.slice(0, maxCount);
  // }
  // const others = members?.length - maxCount;

  return (
    <div className="flex items-center gap-x-2">
      {/* <div className="flex">
        {data?.map(({ user }, index) => (
          <div
            key={user._id}
            className={`w-[${size}] h-[${size}] min-w-[${size}] min-h-[${size}] flex items-center justify-center
                     text-white2 text-base leading-xl1 rounded-full gap-x-[-3px] capitalize`}
            style={{
              backgroundColor: `${colors[index]}`,
              height: size,
              width: size,
              fontSize: fontSize,
            }}
          >
            {user?.firstName[0]}
          </div>
        ))}
      </div> */}
      {/* {others > 0 && (
        <span className="text-grey-content-secondary text-sm leading-base1 cursor-pointer">
          +{others} {others > 1 ? "others" : "other"}
        </span>
      )} */}
    </div>
  );
};

export default AvatarStack;
