import { BlockDataProps } from './components/InfrastructureModal';

const InterfaceTransactionDetails = ({
  transactionData,
}: {
  transactionData: BlockDataProps;
}) => {
  return (
    <div className="pb-10 mt-5 ">
      <div className="flex items-center border-b border-solid border-grey-stroke-tertiary">
        <div className="py-5 bg-modalcontentbg w-1/4 px-4">
          <h6 className="text-base font-semibold leading-5 text-body-black">Hash</h6>
        </div>
        <div className="border-l border-solid border-grey-stroke-tertiary py-5 px-4">
          <p className="text-base font-semibold leading-5 text-grey-content-secondary">
            {transactionData.hash}
          </p>
        </div>
      </div>
      <div className="flex items-center border-b border-solid border-grey-stroke-tertiary">
        <div className="py-5 bg-modalcontentbg w-1/4 px-4">
          <h6 className="text-base font-semibold leading-5 text-body-black">Timestamp</h6>
        </div>
        <div className="border-l border-solid border-grey-stroke-tertiary py-5 px-4">
          <p className="text-base font-semibold leading-5 text-grey-content-secondary">
            {transactionData.timestamp} (Mar-06-2024 06:51:47 PM +UTC)
          </p>
        </div>
      </div>
      <div className="flex items-center border-b border-solid border-grey-stroke-tertiary">
        <div className="py-5 bg-modalcontentbg w-1/4 px-4">
          <h6 className="text-base font-semibold leading-5 text-body-black">
            Transactions
          </h6>
        </div>
        <div className="border-l border-solid border-grey-stroke-tertiary py-5 px-4">
          <p className="text-base font-semibold leading-5 text-grey-content-secondary">
            {transactionData.totalTransactions} transactions
          </p>
        </div>
      </div>
      <div className="flex items-center border-b border-solid border-grey-stroke-tertiary">
        <div className="py-5 bg-modalcontentbg w-1/4 px-4">
          <h6 className="text-base font-semibold leading-5 text-body-black">Method</h6>
        </div>
        <div className="border-l border-solid border-grey-stroke-tertiary py-5 px-4">
          <p className="text-base font-semibold leading-5 text-grey-content-secondary">
            {transactionData.method}
          </p>
        </div>
      </div>
      <div className="flex items-center border-b border-solid border-grey-stroke-tertiary">
        <div className="py-5 bg-modalcontentbg w-1/4 px-4">
          <h6 className="text-base font-semibold leading-5 text-body-black">Status</h6>
        </div>
        <div className="border-l border-solid border-grey-stroke-tertiary py-5 px-4 flex items-center gap-1">
          <div
            className={`w-3 h-3 rounded-full ${transactionData.status === 'Successful' ? 'bg-green-alert' : transactionData.status === 'Pending' ? 'bg-trud-orange' : 'bg-red-alert'}`}
          ></div>
          <p className="text-base font-semibold leading-5 text-grey-content-secondary">
            {transactionData.status}
          </p>
        </div>
      </div>
    </div>
  );
};

export default InterfaceTransactionDetails;
