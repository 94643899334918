import ReactListbox from '@/components/FormElement/ReactListbox';
import RenderChart from '@/components/RenderChart';
import { DropDownOptions } from '@/utils/constants';
import { getMonthRange } from '@/utils/helpers';
import moment from 'moment';
import { useState } from 'react';
import SummaryHeader from './SummaryHeader';

const PartnerNetwork = () => {
  const [range, setRange] = useState<number>(DropDownOptions.DateRangeOptions[0].value);
  const series: {
    name: string;
    data: any[];
  }[] = [
    {
      name: 'Trusted Consumers',
      data: [
        4500, 3825, 4250, 4549, 3521, 3670, 4482, 4000, 4482, 3625, 3750, 3549, 3921,
        4500, 3825, 4250, 4549, 3521, 3670, 4482, 4000, 4482, 3625, 3750, 3549, 3921,
        3521, 3670, 4482, 4000,
      ].splice(30 - Number(range), Number(range)),
    },
    {
      name: 'Medium Risk Consumers',
      data: [
        4051, 3170, 2921, 3049, 3250, 3325, 3325, 3325, 3551, 3170, 2921, 3049, 3250,
        3250, 3170, 2921, 3049, 3250, 3325, 3325, 3325, 3551, 3170, 2921, 3049, 3250,
        2921, 3049, 3250, 3325,
      ].splice(30 - Number(range), Number(range)),
    },
    {
      name: 'High Risk Consumers',
      data: [
        2150, 1960, 1930, 1563, 2033, 1650, 1502, 1530, 1750, 1550, 1950, 2060, 2130,
        1963, 2033, 2250, 1502, 1530, 1450, 1550, 1950, 2060, 2130, 1963, 2233, 1750,
        1502, 1530, 1750, 1250,
      ].splice(30 - Number(range), Number(range)),
    },
  ];

  const overviewData = [
    {
      title: 'Total consumer profiles',
      count: 9837,
      percentage: '+20%',
      isChart: true,
    },
    {
      title: 'Active consumer profiles',
      count: 8051,
      percentage: '+20%',
      isChart: true,
    },
    {
      title: 'Projects',
      count: 5,
      percentage: '+0%',
      isChart: false,
    },
  ];

  return (
    <div className="relative bg-white">
      <SummaryHeader heading="Network overview" overviewData={overviewData} />
      <div className="mb-8">
        <div className="flex items-center justify-between mb-2">
          <p className="text-grey-content-secondary text-base leading-5 font-semibold">
            Risk distribution
          </p>
          <ReactListbox
            selectedOption={range}
            onChange={setRange}
            Options={DropDownOptions.DateRangeOptions}
          />
        </div>
        <div className="border border-solid border-grey-stroke-primary rounded-lg px-4">
          <div className="grid grid-cols-3 gap-5">
            <div className="relative py-4 border-r border-solid border-grey-stroke-primary">
              <div className="relative">
                <div className="flex items-center gap-1 mb-1">
                  <div className="h-3 w-3 bg-risk-green rounded-sm"></div>
                  <p className="text-base font-semibold leading-5 text-grey-content-secondary">
                    Trusted Consumers
                  </p>
                </div>
                <h3 className="text-xl2 font-semibold leading-2xl2 text-black mb-1">
                  4982
                </h3>
                <span className="text-score-green font-semibold text-sm leading-sm">
                  +20%
                </span>
              </div>
            </div>
            <div className="relative py-4 border-r border-solid border-grey-stroke-primary">
              <div className="relative">
                <div className="flex items-center gap-1 mb-1">
                  <div className="h-3 w-3 bg-trud-orange rounded-sm"></div>
                  <p className="text-base font-semibold leading-5 text-grey-content-secondary">
                    Medium Risk Consumers
                  </p>
                </div>
                <h3 className="text-xl2 font-semibold leading-2xl2 text-black mb-1">
                  3325
                </h3>
                <span className="text-score-green font-semibold text-sm leading-sm">
                  +10%
                </span>
              </div>
            </div>
            <div className="relative py-4 rounded-lg">
              <div className="relative">
                <div className="flex items-center gap-1 mb-1">
                  <div className="h-3 w-3 bg-risk-red rounded-sm"></div>
                  <p className="text-base font-semibold leading-5 text-grey-content-secondary">
                    High Risk Consumers
                  </p>
                </div>
                <h3 className="text-xl2 font-semibold leading-2xl2 text-black mb-1">
                  1530
                </h3>
                <span className="text-score-green font-semibold text-sm leading-sm">
                  -5%
                </span>
              </div>
            </div>
          </div>
          <div className="relative w-full text-center my-5">
            <RenderChart
              series={series}
              height={500}
              chartOptions={{
                colors: ['#0CCFA0', '#EFB604', '#E32D2D'],
                xaxis: {
                  type: 'category',
                  categories: getMonthRange(
                    moment().subtract(range, 'days').startOf('day').toDate(),
                    moment().startOf('day').toDate()
                  ),
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerNetwork;
