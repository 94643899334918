import { createContext, useState, useEffect } from "react";

// Utils
import { getRandomInt } from "@utils/helpers";
import { axiosInstance } from "@utils/baseAxios";
export const authContext = createContext();

const AuthContextProvider = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [email, setEmail] = useState();
  const [hasSetup, setHasSetup] = useState(true);
  const [updatedShopperTrustedScore, setUpdatedShopperTrustedScore] = useState(
    []
  );
  const [randNum, setRandNum] = useState();

  const logout = async () => {
    await axiosInstance
      .delete("/clear_session")
      .then(() => {
        setIsAuthenticated(false);
        localStorage.clear();
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    setRandNum(getRandomInt(4));
  }, []);

  return (
    <authContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        logout,
        hasSetup,
        setHasSetup,
        email,
        setEmail,
        randNum,
        updatedShopperTrustedScore,
        setUpdatedShopperTrustedScore,
      }}
    >
      {props.children}
    </authContext.Provider>
  );
};

export default AuthContextProvider;
