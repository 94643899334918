import { useNavigate } from "react-router-dom";

// Utils
import { getClassNames } from "@utils/helpers";

// Assets
import { ReactComponent as CloseIcon } from "@icons/close-lg.svg";
import { ReactComponent as DashboardIllustration } from "@images/dashboard-illustration2.svg";
import { Button } from "@components/button";

const GetStarted = () => {
  const navigate = useNavigate();

  return (
    <div className="w-full h-full flex flex-col">
      <div
        className={getClassNames(
          "relative container-solutions max-w-[800px] h-full md:h-fit flex flex-col items-center justify-center mt-16",
          "px-4 min-h-[776px] md:min-h-[auto]"
        )}
      >
        <div
          className="absolute rounded-full p-3 flex items-center justify-center bg-stroke cursor-pointer right-0 xl:-right-40 top-8"
          onClick={() => navigate("/")}
        >
          <CloseIcon className="w-3 h-3" />
        </div>
        <h5 className="text-center text-base1 mb-4 text-grey-content-tertiary">
          Introduction
        </h5>
        <h1 className="text-[2.875rem] text-center  mt-3 mb-10 text-body-black">
          Shopper Trust Index
        </h1>
        <div className="max-w-[518px] flex flex-col items-center">
          <p className="text-center text-grey-content-secondary text-base1">
            The Shopper Trust Index is a fraud risk score that helps you
            differentiate trusted shoppers from high risk shoppers. <br />{" "}
            <br /> Customise your Shopper Trust Index algorithm, or leverage our
            recommendations.
          </p>
          <Button
            title="Get Started"
            color="dark"
            className="w-full max-w-[383px] mt-5 py-3"
            onClick={() => navigate("/projects/configure", { replace: true })}
          />

        </div>
      </div>
      <DashboardIllustration className="mt-24 mx-auto w-full" />
    </div>
  );
};

export default GetStarted;
