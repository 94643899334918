import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '@/redux/store';

// Redux
import {
  Item,
  setShopperTrustTotal,
  saveModalHandler,
  publishModalHandler,
  getTrustedShopperConfig,
  Config,
} from '@redux/features/projects';

// Context
import { DemoGuidePopoverContext } from '@context/demoGuidePopover';

// Utils
import { SHOPPER_TRUST_INDEX, config_type } from '@utils/constants';

// Components
import DemoGuidePopover from '@/components/DemoGuidePopover';
import ConfigUpdater from './configupdater';
import FraudRiskDropDown from './fraudRiskDropDown';
import { axiosInstance } from '@/utils/baseAxios';
import {
  ShopperScoreOrder,
  getLocalStorageData,
  setLocalStorageData,
} from '@/utils/helpers';
import { toast } from 'react-toastify';
import { authContext } from '@/context/auth';

type ShopperTrustConfigSetupProps = {
  step: number;
  stepsData: any;
  stage: number;
  setStage: React.Dispatch<React.SetStateAction<number>>;
  register: any;
  fraudRiskValue?: {
    id: string;
    title: string;
    description: string;
  };
  setFraudRiskValue: (value: any) => void;
};

type ShopperTrustIndexSubCategoryProps = {
  id: string;
  name: string;
  score: string;
  recommended: string;
  description: string;
};

const ShopperTrustConfigSetup: React.FC<ShopperTrustConfigSetupProps> = ({
  step,
  stepsData,
  stage,
  setStage,
  register,
  fraudRiskValue,
  setFraudRiskValue,
}) => {
  const dispatch = useDispatch();
  const { setUpdatedShopperTrustedScore } = useContext(authContext);
  const [shopperTrustedScores, setShopperTrustedScores] = useState<any>();
  const [shopperTrustConfigScore, setShopperConfigScore] = useState<any>([]);
  const [optionsToReveal, setOptionsToReveal] = useState<any>([]);
  const { activeProject, projectType, shopperTrustTotal, shopperTrustConfig } =
    useSelector((state: IRootState) => state.projectSlice);

  useEffect(() => {
    if (activeProject) {
      dispatch(getTrustedShopperConfig(activeProject?.id) as any);
      setFraudRiskValue(activeProject?.fraud_risk_type);
      setStage(1);
    }

    const shopperData = localStorage.getItem(`project_${activeProject?.id}`);
    setShopperTrustedScores(JSON.parse(shopperData ?? '{}'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchShopperDefaultScoreByFraudTypeName = async (name: string) => {
    try {
      const res = await axiosInstance.get(`/fraud_risk_types/by_type_name/${name}`);
      if (res.status === 200 && res.data) {
        res.data?.shopper_trusted_score_list?.map((score: any) => {
          shopperTrustedScores?.config?.map((scoreData: any) => {
            if (scoreData.name === score.name) {
              scoreData.score = score.score.toFixed(2);
              scoreData.recommended = score.recommended.toFixed(2);

              score.sub_category?.map((subScore: any) => {
                scoreData.shopper_trust_index_sub_categories?.map((scores: any) => {
                  if (scores.name === subScore.name) {
                    Object.assign(scores, {
                      score: subScore.score.toFixed(2),
                      recommended: subScore.recommended.toFixed(2),
                    });
                  }
                  return scores;
                });
                return subScore;
              });
            }
            return scoreData;
          });
          setShopperTrustedScores({ ...shopperTrustedScores });
          return score;
        });
      }
    } catch (error) {
      console.log('ERROR', error);
    }
  };

  useEffect(() => {
    if (fraudRiskValue && activeProject?.fraud_risk_type_id !== fraudRiskValue.id)
      fetchShopperDefaultScoreByFraudTypeName(fraudRiskValue.title);
    else {
      setShopperTrustedScores(getLocalStorageData(`project_${activeProject?.id}`));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeProject, fraudRiskValue]);

  const onSave = () => {
    if (shopperTrustTotal === 100) {
      const localData = getLocalStorageData(`project_${activeProject?.id}`);

      if (localData?.config?.length && shopperTrustConfigScore.length) {
        shopperTrustConfigScore.forEach((obj2: any) => {
          const obj1ToUpdate = localData?.config.find((obj1: any) => obj1.id === obj2.id);
          if (obj1ToUpdate) {
            Object.assign(obj1ToUpdate, obj2);
          }
        });
        setLocalStorageData(`project_${activeProject?.id}`, JSON.stringify(localData));

        const activeData = getLocalStorageData(`project${activeProject?.id}`) || null;
        if (
          activeData?.data.length &&
          fraudRiskValue &&
          activeData.data[0].fraud_risk_type_id !== fraudRiskValue.id
        ) {
          activeData.data[0].fraud_risk_type = fraudRiskValue;
          activeData.data[0].fraud_risk_type_id = fraudRiskValue.id;
          setLocalStorageData(`project${activeProject?.id}`, JSON.stringify(activeData));
        }
        setOpenedRow(undefined);
      }
    } else {
      toast.error('Weightings can only add up to a maximum of 100');
    }
  };

  useEffect(() => {
    if (shopperTrustedScores?.config?.length) {
      let res = shopperTrustedScores?.config.filter(
        (attr: Config) => attr.environment === 'SANDBOX'
      );
      res = ShopperScoreOrder(res);
      setUpdatedShopperTrustedScore(res);
      setShopperConfigScore(res);
      if (res.length)
        setOptionsToReveal({
          ...(res.reduce((acc: any, obj: any) => {
            acc[obj.name] = obj.shopper_trust_index_sub_categories;
            return acc;
          }, {}) || {}),
        });
    }
  }, [setUpdatedShopperTrustedScore, shopperTrustedScores]);

  const trustIndexAtrr = shopperTrustConfig || [];
  const [editableScores] = useState<Record<string, number>>(() => {
    const edt: Record<string, number> = {};
    trustIndexAtrr?.forEach(
      (item: { name: string; score: string; config_type: config_type }) => {
        edt[item.name] = parseInt(item.score);
      }
    );
    return edt;
  });

  const updateScore = (name: string, score: number, parentCategory: string) => {
    const updatedData = shopperTrustConfigScore?.map((item: any) => {
      if (item.name === parentCategory) {
        const subCategory = item.shopper_trust_index_sub_categories.find(
          (item: any) => item.name === name
        );
        if (subCategory) {
          Object.assign(subCategory, {
            score: score.toFixed(2),
          });
        }

        const mainScore = item.shopper_trust_index_sub_categories.reduce(
          (accumulator: number, currentValue: Config) => {
            return accumulator + parseInt(currentValue.score);
          },
          0
        );
        item.score = mainScore.toFixed(2);
      }
      return item;
    });
    const edt: Record<string, number> = {};
    updatedData?.forEach(
      (item: {
        name: string;
        score: string;
        shopper_trust_index_sub_categories: ShopperTrustIndexSubCategoryProps[];
      }) => {
        edt[item.name] = parseInt(item.score);
      }
    );
    setShopperConfigScore([...updatedData]);
    setUpdatedShopperTrustedScore([...updatedData]);
    dispatch(setShopperTrustTotal({ ...edt }));
  };

  useEffect(() => {
    dispatch(saveModalHandler(false));
    dispatch(publishModalHandler(false));
  }, [dispatch]);

  const [openedRow, setOpenedRow] = useState<number | undefined>();

  const {
    setPopUp10,
    popUp10,
    setPopUp11,
    setPopUp13,
    popUp14,
    setPopUp14,
    popUp15,
    setPopUp15,
    popUp16,
    setPopUp16,
    setPopUp17,
  } = React.useContext(DemoGuidePopoverContext);
  return step > 0 && projectType !== SHOPPER_TRUST_INDEX ? null : (
    <>
      <FraudRiskDropDown {...{ fraudRiskValue, setFraudRiskValue, setStage, step, stage }} />
      {stage === 0 && <div className="min-h-[500px]" />}

      {stage === 1 && (
        <>
          <div id="target10" className="relative flex items-baseline justify-between">
            <DemoGuidePopover
              title="Fraud risk score - max 100"
              body="The algorithm is designed to report a fraud risk score out of 100 for consumers"
              el={() => document.getElementById('target10')}
              topOffset={0}
              leftOffset={350}
              arrowPosition="right"
              maxWidth="max-w-[355px]"
              open={popUp10}
              setOpen={setPopUp10}
              setNext={setPopUp11}
              noArrow
            />

            <DemoGuidePopover
              title="Customising the Shopper Trust Index"
              body="Customise the Shopper Trust Index algorithm by specifying how the ML algorithm should weight data and the calculation along the 5 categories"
              el={() => document.getElementById('target10')}
              topOffset={-180}
              leftOffset={400}
              arrowPosition="bottom"
              maxWidth="max-w-[355px]"
              open={popUp14}
              setOpen={setPopUp14}
              setPrev={setPopUp13}
              setNext={setPopUp15}
            />

            <DemoGuidePopover
              title="Try it - Customize the score"
              body="Change the weighting on each category to tailor the algorithm to address your organisation’s use case"
              el={() => document.getElementById('target10')}
              topOffset={130}
              leftOffset={400}
              arrowPosition="top"
              maxWidth="max-w-[355px]"
              open={popUp16}
              setOpen={setPopUp16}
              setPrev={setPopUp15}
              setNext={setPopUp17}
            />
          </div>
          <div className="relative flex items-baseline justify-between">
            <h5 className=" text-grey-content-secondary text-lg">
              {stepsData[step][stage]['info']}
            </h5>
            <div className="w-[200px] flex items-center justify-center" id="targetR">
              <span className="text-sm1 text-grey-content-tertiary leading-0 h-fit text-center">
                Recommended
              </span>
              <DemoGuidePopover
                title="ML recommends the optimal configurations"
                body="The ML algorithm recommends the optimal construct of the algorithm for organisations of your kind based on the available network data"
                el={() => document.getElementById('targetR')}
                topOffset={-210}
                leftOffset={-80}
                arrowPosition="bottom"
                maxWidth="max-w-[355px]"
                open={popUp15}
                setOpen={setPopUp15}
                setPrev={setPopUp14}
                setNext={setPopUp16}
              />
            </div>
          </div>
          <div className="mt-2 rounded border border-grey-10 flex flex-col divide-y-[1px] divide-grey-10">
            {shopperTrustConfigScore?.map((attr: Item, i: number) => {
              return (
                <ConfigUpdater
                  {...{
                    optionsToReveal,
                    attr,
                    register,
                    i,
                    openedRow,
                    setOpenedRow,
                    editableScores,
                    stage,
                    updateScore,
                    onSave,
                  }}
                />
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

export default ShopperTrustConfigSetup;
