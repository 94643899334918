import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Transition, Listbox } from "@headlessui/react";

// Helpers
import { getClassNames } from "@utils/helpers";

// Redux
import { IRootState } from "@redux/store";
import { setRiskLevelFilter } from "@redux/features/projects";

// Assets
import { ReactComponent as CaretIcon } from "@icons/arrow_down.svg";

const ShopperFilterDropdown = () => {
  const dispatch = useDispatch();
  const { riskLevelFilter } = useSelector(
    (state: IRootState) => state.projectSlice
  );

  const options = [
    { label: "Recent", value: "" },
    { label: "Trusted Shoppers", value: "LOW_RISK_SHOPPER" },
    { label: "Medium Risk Shoppers", value: "MEDIUM_RISK_SHOPPER" },
    { label: "High Risk Shoppers", value: "HIGH_RISK_SHOPPER" },
  ];

  return (
    <Listbox
      value={riskLevelFilter}
      onChange={(opt) => dispatch(setRiskLevelFilter(opt))}
    >
      {({ open }) => {
        return (
          <div
            className={getClassNames(
              "relative border border-stroke bg-white py-1 px-2 rounded-2xl"
            )}
          >
            <Listbox.Button
              className={getClassNames(
                "flex items-center gap-x-2 justify-between w-full",
                "bg-white4 border-0 text-left cursor-pointer",
                "focus:outline-none focus:ring-0 focus:border-0 w-full"
              )}
            >
              <div className="flex gap-x-2 items-center">
                <span
                  className={getClassNames(
                    "text-sm1  text-body-black"
                  )}
                >
                  {riskLevelFilter.label}
                </span>
              </div>

              <span className="pointer-events-none">
                <CaretIcon />
              </span>
            </Listbox.Button>
            <div className="relative" id="positionEl" />

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={getClassNames(
                  "absolute right-0 top-10 w-max bg-white z-[100] -m-2 px-2 py-1  rounded",
                  "text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none",
                  "sm:text-sm min-w-[8rem] shadow-[0px_6px_10px_2px_rgba(218,218,219,0.5)]"
                )}
              >
                {options?.map((option) => (
                  <Listbox.Option
                    key={option.label}
                    className={({ active }) =>
                      getClassNames(
                        "cursor-pointer py-1 select-none relative rounded "
                      )
                    }
                    value={option}
                  >
                    <div
                      className={getClassNames(
                        "flex gap-x-2 items-center w-full px-2 py-1 hover:bg-grey rounded",
                        riskLevelFilter.value === option.value ? "bg-grey" : ""
                      )}
                    >
                      <span
                        className={getClassNames(
                          "block truncate w-full text-sm1 leading-5 font-semibold",
                          riskLevelFilter.value === option.value
                            ? "text-body-black "
                            : "text-grey-content-tertiary"
                        )}
                      >
                        {option.label}
                      </span>
                    </div>
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        );
      }}
    </Listbox>
  );
};

export default ShopperFilterDropdown;