import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";

// Helpers
import { getClassNames } from "@utils/helpers";

// Hooks
import { IRootState } from "@/redux/store";
import {
  publishModalHandler,
  publishSuccessModalHandler,
  publishProject
} from "@redux/features/projects";

// Components
import { Button } from "@components/button";
import { InputContainer } from "@components/input";

// Assets
import CaretSm from "@icons/CaretSm";
import { ReactComponent as CloseIcon } from "@icons/close-lg.svg";
import { ReactComponent as CircleCheckIcon } from "@icons/check-circle.svg";
import { ReactComponent as CopyIcon } from "@icons/copy.svg";
import { ReactComponent as RRFManageIcon } from "@icons/rrfmanager.svg";
import { ProjectEnvIndicator } from "@components/ProjectStateToggle";
import ToolTip from "@components/ToolTip";

const PublishModal = () => {
  const { activeProject, showPublishModal, loading } = useSelector(
    (state: IRootState) => state.projectSlice
  );

  const [showShopperOptions, setShowShopperOptions] = useState(false);
  const dispatch = useDispatch();

  const onPublish = async () => {
    if(!activeProject) return;
    await dispatch(publishProject(activeProject?.project_id) as any)
      .unwrap()
      .then(() => {
        dispatch(publishModalHandler(false));
        dispatch(publishSuccessModalHandler(true));
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  return (
    <Transition appear show={showPublishModal} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={getClassNames(
                  `w-full min-w-[840px] max-w-[840px] transform rounded-2xl bg-white text-left align-middle 
                shadow-[0px_4px_10px_rgba(0,0,0,0.05)] transition-all p-10`
                )}
              >
                <div className="flex items-center justify-between">
                  <div className="text-grey-content-secondary rounded-3xl bg-grey-stroke-primary px-2 py-0.5">
                    Step 3
                  </div>
                  <CloseIcon
                    onClick={() => dispatch(publishModalHandler(false))}
                    className="cursor-pointer"
                  />
                </div>
                <h3 className="header3 mt-4 mb-2">Publish</h3>
                <p className="text-grey-content-tertiary paragraph1">
                  Delight your trusted shoppers with instant refunds and
                  eliminate manual refund processing by <br />
                  integrating the Checkout and Refund Manager. <br />
                  Generate your unique API key, copy it, and deploy. Our team is
                  available 24/7 to support you.
                </p>
                <h6 className="text-desc-grey mt-6">Products</h6>

                <div className="mt-2 border border-grey-stroke-primary rounded h-">
                  <div className="border-b border-grey-stroke-primary ">
                    <div className="flex justify-between px-6 py-4">
                      <div className="flex gap-x-3 items-center">
                        <RRFManageIcon className="w-6 h-6" />
                        <h5 className="header5">
                          {activeProject?.name || "Payment Personalisation"}
                        </h5>
                        <span
                          className="p-2 pl-0 cursor-pointer"
                          onClick={() => setShowShopperOptions((prev) => !prev)}
                        >
                          <CaretSm className="rotate-180" color="#747678" />
                        </span>
                      </div>
                      <ProjectEnvIndicator />
                    </div>

                    <div
                      className={getClassNames(
                        "transition-all duration-300",
                        showShopperOptions
                          ? "h-[134px] opacity-100"
                          : "h-0 overflow-hidden opacity-0"
                      )}
                    >
                      <div className="flex gap-x-2 items-center px-6 py-3">
                        <CircleCheckIcon />
                        <span className="text-grey-content-secondary">
                          Shopper Trust Index Configuration
                        </span>
                      </div>
                      <div className="flex gap-x-2 items-center px-6 py-3 border-t border-grey-stroke-primary">
                        <CircleCheckIcon />
                        <span className="text-grey-content-secondary">
                          Instant Refunds Orchestrator
                        </span>
                      </div>
                      <div className="flex gap-x-2 items-center px-6 py-3 border-t border-grey-stroke-primary">
                        <CircleCheckIcon />
                        <span className="text-grey-content-secondary">
                          Trusted Shopper Network Search and Analytics
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="relative border-b border-grey-stroke-primary px-6 pt-6 pb-8">
                    <InputContainer
                      className="relative group text-grey-content-secondary paragraph1 bg-stroke-primary"
                      style={{ background: "#EFEFEF" }}
                    >
                      Access to Customer’s Shopper Trust Index lasts for 180
                      days or until permission is revoked.
                      <ToolTip
                        className="left-4 max-w-[230px]"
                        info="Once consumer consent permissions have expired or have been revoked - a renewal should be triggered "
                      />
                    </InputContainer>
                  </div>
                  <div className="border-b border-grey-stroke-primary px-6 pt-6 pb-8">
                    <div className="flex gap-x-3 items-center end-full">
                      <Button
                        title="Sandbox API key"
                        category="2"
                        color=""
                        className={getClassNames("bg-grey-stroke-primary")}
                        onClick={() => {}}
                      />
                      <Button
                        title="API Documentation"
                        category="2"
                        color="light"
                        className={getClassNames("")}
                        onClick={() => {}}
                      />
                    </div>
                    <InputContainer
                      className="flex items-center justify-between mt-8 mb-4 text-grey-content-secondary  bg-stroke-primary"
                      style={{ background: "#F8F8F8" }}
                    >
                      <span className="">
                        {activeProject?.api_key}
                      </span>
                      <CopyIcon className="cursor-pointer" />
                    </InputContainer>
                    <Button
                      title="Generate new API key"
                      category="2"
                      color="light"
                      className={getClassNames("")}
                      onClick={() => {}}
                    />
                  </div>
                </div>
                <div className="flex justify-between mt-8">
                  <div className="flex gap-x-3 items-center">
                    <Button
                      title="Unpublish"
                      category="1"
                      color="dark"
                      disabled
                      className={getClassNames("bg-grey-stroke-primary")}
                      onClick={() => {}}
                    />
                    <Button
                      title="Publish"
                      category="1"
                      color="dark"
                      loading={loading}
                      className={getClassNames("")}
                      onClick={() => onPublish()}
                    />
                  </div>
                  <Button
                    title="Contact us"
                    category="1"
                    color="light"
                    className={getClassNames("")}
                    onClick={() => {}}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default PublishModal;
