import { getClassNames } from '@/utils/helpers';
import { Dialog, Transition } from '@headlessui/react';
import { ReactComponent as CloseIcon } from '@icons/cancel.svg';
import { Fragment } from 'react';
import InterfaceTransactionDetails from './InterfaceTransactionDetails';
import { BlockDataProps } from './components/InfrastructureModal';

const InfrastructureModalTransaction = ({
  isOpen,
  closeModal,
  transactionData,
}: {
  isOpen: boolean;
  closeModal: (val: boolean) => void;
  transactionData: BlockDataProps;
}) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-20" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed top-20 left-0 right-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={getClassNames(
                  `w-full max-w-[530px] transform overflow-hidden rounded-2xl bg-white text-left align-middle 
                shadow-[0px_4px_10px_rgba(0,0,0,0.05)] transition-all`
                )}
              >
                <Dialog.Title
                  as="h3"
                  className="flex justify-between items-center text-base1 leading-xl1 text-body-black py-5 px-[1.625rem] rounded-t-2xl"
                >
                  <span className="text-xl font-semibold leading-5 text-body-black">
                    Transaction
                  </span>

                  <CloseIcon
                    onClick={() => closeModal(false)}
                    className="cursor-pointer"
                  />
                </Dialog.Title>
                <InterfaceTransactionDetails transactionData={transactionData} />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default InfrastructureModalTransaction;
