import ReactListbox from '@/components/FormElement/ReactListbox';
import SearchInput from '@/components/searchInput';
import { ROLES } from '@/utils/constants';
import { getClassNames, getRandomKeyInt } from '@/utils/helpers';
import { Dialog, Tab, Transition } from '@headlessui/react';
import { ReactComponent as CloseIcon } from '@icons/cancel.svg';
import { Fragment, useState } from 'react';

export type BlockDataProps = {
  hash: string;
  blockNo: string;
  method: string;
  totalTransactions: string;
  timestamp: string;
  status: string;
};
const InfrastructureModal = ({
  isOpen,
  closeModal,
  blockData,
  displayData,
}: {
  isOpen: boolean;
  closeModal: (val: boolean) => void;
  blockData: BlockDataProps | null;
  displayData: BlockDataProps[];
}) => {
  const [currentRole, setCurrentRole] = useState<string>('all');

  const tabOptions = ['Overview', 'Transactions'];

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-20" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed top-20 left-0 right-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={getClassNames(
                  `w-full max-w-[530px] transform overflow-hidden rounded-2xl bg-white text-left align-middle 
                shadow-[0px_4px_10px_rgba(0,0,0,0.05)] transition-all`
                )}
              >
                <Dialog.Title
                  as="h3"
                  className="flex justify-end items-center text-base1 leading-xl1 text-body-black py-5 px-[1.625rem] rounded-t-2xl"
                >
                  <CloseIcon
                    onClick={() => closeModal(false)}
                    className="cursor-pointer"
                  />
                </Dialog.Title>
                <div className="relative">
                  <p className="text-base font-semibold leading-5 text-grey-content-secondary text-center mb-5">
                    Block
                  </p>
                  <div className="text-center">
                    <div className="bg-grey-stroke-tertiary border border-solid border-grey-stroke-primary rounded-lg px-10 py-5 inline-block mx-auto mb-8">
                      <div className="flex items-center gap-2">
                        <span className="bg-grey-content-quaternary rounded py-1 px-2.5 text-xl2 font-semibold leading-2xl2 -tracking-0.2 text-white">
                          #
                        </span>
                        <span>{blockData?.blockNo}</span>
                      </div>
                    </div>
                  </div>
                  <div className="relative flex items-center justify-between px-4 py-6">
                    <div className="relative">
                      <Tab.Group>
                        <div className="flex items-center gap-5">
                          <Tab.List className=" flex gap-4">
                            {tabOptions?.map((tabTitle) => (
                              <Tab key={getRandomKeyInt()}>
                                {({ selected }) => (
                                  <div
                                    className={getClassNames(
                                      'px-4 py-2 border border-grey-stroke-primary rounded',
                                      selected
                                        ? 'text-trud-black bg-grey-stroke-primary'
                                        : 'text-grey-content-secondary'
                                    )}
                                  >
                                    {tabTitle}
                                  </div>
                                )}
                              </Tab>
                            ))}
                          </Tab.List>
                          <div className="flex items-center gap-2">
                            <div className="relative">
                              <ReactListbox
                                Options={[
                                  { label: 'All', value: 'all' },
                                  ...Object.values(ROLES).map((roleData) => {
                                    return { label: roleData, value: roleData };
                                  }),
                                ]}
                                onChange={(newValue) => {
                                  setCurrentRole(newValue);
                                }}
                                selectedOption={currentRole}
                                btnClass="min-w-28"
                              />
                            </div>
                            <SearchInput
                              handleChange={(searchInput) => {
                                console.log({ searchInput });
                              }}
                            />
                          </div>
                        </div>
                        <Tab.Panels>
                          <Tab.Panel>
                            <div className="pb-10 mt-5 ">
                              <div className="flex items-center border-b border-solid border-grey-stroke-tertiary">
                                <div className="py-5 bg-modalcontentbg w-1/4 px-4">
                                  <h6 className="text-base font-semibold leading-5 text-body-black">
                                    Hash
                                  </h6>
                                </div>
                                <div className="border-l border-solid border-grey-stroke-tertiary py-5 px-4">
                                  <p className="text-base font-semibold leading-5 text-grey-content-secondary">
                                    {blockData?.hash}
                                  </p>
                                </div>
                              </div>
                              <div className="flex items-center border-b border-solid border-grey-stroke-tertiary">
                                <div className="py-5 bg-modalcontentbg w-1/4 px-4">
                                  <h6 className="text-base font-semibold leading-5 text-body-black">
                                    Timestamp
                                  </h6>
                                </div>
                                <div className="border-l border-solid border-grey-stroke-tertiary py-5 px-4">
                                  <p className="text-base font-semibold leading-5 text-grey-content-secondary">
                                    {blockData?.timestamp} (Mar-06-2024 06:51:47 PM +UTC)
                                  </p>
                                </div>
                              </div>
                              <div className="flex items-center border-b border-solid border-grey-stroke-tertiary">
                                <div className="py-5 bg-modalcontentbg w-1/4 px-4">
                                  <h6 className="text-base font-semibold leading-5 text-body-black">
                                    Transactions
                                  </h6>
                                </div>
                                <div className="border-l border-solid border-grey-stroke-tertiary py-5 px-4">
                                  <p className="text-base font-semibold leading-5 text-grey-content-secondary">
                                    {blockData?.totalTransactions} transactions
                                  </p>
                                </div>
                              </div>
                              <div className="flex items-center border-b border-solid border-grey-stroke-tertiary">
                                <div className="py-5 bg-modalcontentbg w-1/4 px-4">
                                  <h6 className="text-base font-semibold leading-5 text-body-black">
                                    Status
                                  </h6>
                                </div>
                                <div className="border-l border-solid border-grey-stroke-tertiary py-5 px-4 flex items-center gap-1">
                                  <div
                                    className={`w-3 h-3 rounded-full ${blockData?.status === 'Successful' ? 'bg-green-alert' : blockData?.status === 'Pending' ? 'bg-trud-orange' : 'bg-red-alert'}`}
                                  ></div>
                                  <p className="text-base font-semibold leading-5 text-grey-content-secondary">
                                    {blockData?.status}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Tab.Panel>
                          <Tab.Panel>
                            <div className="pb-10 mt-5">
                              <table className="w-full">
                                <tbody>
                                  {displayData
                                    .filter(
                                      (display) => display.blockNo === blockData?.blockNo
                                    )
                                    .map((data, index) => (
                                      <tr className="" key={getRandomKeyInt()}>
                                        <td className="text-body-black text-base font-semibold leading-5 py-5 px-4 border border-solid border-grey-stroke-tertiary">
                                          {index + 1}
                                        </td>
                                        <td className="text-body-black text-base font-semibold leading-5 py-5 px-4 border border-solid border-grey-stroke-tertiary">
                                          {data.hash.substring(0, 17) + '...'}
                                        </td>
                                        <td className="text-body-black text-base font-semibold leading-5 py-5 px-4 border border-solid border-grey-stroke-tertiary">
                                          <div className="flex items-center gap-1">
                                            <div
                                              className={`w-3 h-3 rounded-full  ${data.status === 'Successful' ? 'bg-green-alert' : data.status === 'Pending' ? 'bg-trud-orange' : 'bg-red-alert'}`}
                                            ></div>
                                            <p className="text-base font-semibold leading-5 text-grey-content-secondary">
                                              {data.status}
                                            </p>
                                          </div>
                                        </td>
                                        <td className="text-grey-content-secondary text-base font-semibold leading-5 py-5 px-4 border border-solid border-grey-stroke-tertiary">
                                          {data.timestamp}
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </Tab.Panel>
                        </Tab.Panels>
                      </Tab.Group>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default InfrastructureModal;
