import { Fragment, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";

// Utils
import { getClassNames } from "@utils/helpers";

// Context
import { authContext } from "@context/auth";
import { DemoGuidePopoverContext } from "@context/demoGuidePopover";

// Redux
import { IRootState } from "@redux/store";
import { publishSuccessModalHandler } from "@redux/features/projects";

// Components
import { Button } from "@components/button";

// Assets
import { ReactComponent as DashboardIllustration } from "@images/dashboard-checkout-and-refunds.svg";

const PublishSuccess = () => {
  const { setHasSetup } = useContext(authContext);
  const { showPublishSuccessModal, activeProject } = useSelector(
    (state: IRootState) => state.projectSlice
  );
  const { setExploreDemo } = useContext(DemoGuidePopoverContext);
  const navigate = useNavigate();
  const dispatch = useDispatch()

  return (
    <Transition appear show={showPublishSuccessModal} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed left-0 right-0 top-20 overflow-y-auto">
          <div className="flex items-center justify-center text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={getClassNames(
                  `w-full min-w-[696px] max-w-[696px]  transform text-left align-middle 
                shadow-[0px_4px_10px_rgba(0,0,0,0.05)] transition-all rounded-2xl`
                )}
              >
                <div className="bg-body-black pt-10 rounded-t-2xl overflow-hidden h-[350px]">
                  <DashboardIllustration className="w-full h-full" />
                </div>
                <div className="p-10 bg-white rounded-b-2xl">
                  <h4 className="header4 mb-2">Publish successful!</h4>
                  <p className="text-grey-content-secondary paragraph1">
                    Well done on successfully setting up the {activeProject?.name}. <br />
                    Use the dashboard to view shopper profiles.
                  </p>

                  <div className="flex gap-x-2 items-center mt-16">
                    <Button
                      title="Go to dashboard"
                      category="1"
                      color="light"
                      onClick={() => {
                        dispatch(publishSuccessModalHandler(false))
                        setHasSetup(true);
                        setExploreDemo(true);
                        navigate("/home")
                      }}
                    />
                    {/* <Button
                      title="Set up payments"
                      category="1"
                      color="dark"
                      onClick={() => {
                        setHasSetup(true);
                        dispatch(publishSuccessModalHandler(false))
                        navigate("/setup-payment");
                      }}
                    /> */}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default PublishSuccess;
