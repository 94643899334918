import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Helpers
import { getClassNames } from '@utils/helpers';

// Contexts
import { DemoGuidePopoverContext } from '@context/demoGuidePopover';

// Components
import DemoGuidePopover from '@components/DemoGuidePopover';
// import OrganizationSwitch from "@pages/organization/components/OrganizationSwitch";

// Assets
import { ReactComponent as PlatformLogo } from '@icons/bank-co-logo.svg';

const noSidebarPaths = [
  'documentation',
  'projects/new',
  'projects/edit',
  'projects/configure',
  'projects/sti',
  'projects/cr',
  'setup-payment',
];
const SideBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeNav, setActiveNav] = useState(1);
  const [showNav, setShowNav] = useState(true);
  const {
    popUp1,
    setPopUp1,
    setPopUp2,
    setPopUp4,
    setPopUp5,
    setPopUp6,
    popUp6,
  } = React.useContext(DemoGuidePopoverContext);

  useEffect(() => {
    setShowNav(true);
    if (location.pathname.includes('home')) {
      setActiveNav(1);
    } else if (
      location.pathname.includes('org-verification') ||
      location.pathname.includes('settings')
    ) {
      setActiveNav(100);
    } else if (location.pathname.includes('users')) {
      setActiveNav(3);
    } else if (location.pathname.includes('groups')) {
      setActiveNav(4);
    } else if (location.pathname.includes('projects/manage')) {
      setActiveNav(2);
    } else if (
      noSidebarPaths.some((path) => location.pathname.includes(path))
    ) {
      setShowNav(false);
    } else {
      setActiveNav(1);
    }
  }, [location]);
  const navOptions1 = [
    {
      id: 1,
      name: 'Home',
      to: '/',
    },
    {
      id: 2,
      name: 'Projects',
      to: '/projects/manage',
    },
    {
      id: 3,
      name: 'Users',
    },
    {
      id: 4,
      name: 'Organization',
    },
  ];
  const navOptions2 = [
    {
      id: 5,
      name: 'Docs',
    },
    {
      id: 6,
      name: 'Support',
    },
  ];

  const { activeProject } = useSelector((state) => state.projectSlice);

  return (
    <div
      className={getClassNames(
        'min-h-screen bg-primary flex flex-col transition-all border border-stroke',
        showNav ? 'min-w-[245px]' : 'hidden',
      )}
    >
      <div className='pl-6 h-[62px] flex items-center'>
        <PlatformLogo
          className='cursor-pointer'
          onClick={() => {
            navigate('/');
          }}
        />
      </div>
      <hr className='border-theme-stroke' />
      <div className='flex-1 bg-primary'>
        <div
          className='bg-primary mt-5 pl-6 pr-4 flex flex-col gap-y-0.5 pb-4'
          id='target1'
        >
          <DemoGuidePopover
            title='Home'
            body='The operational dashboard supporting  operations teams 
                  with consumer fraud data, analytics and tools to prevent 
                  fraud.'
            el={() => document.getElementById('target1')}
            topOffset={40}
            leftOffset={25}
            arrowPosition='right'
            maxWidth='max-w-[355px]'
            open={popUp1 && location?.pathname === '/home'}
            setOpen={setPopUp1}
            setNext={setPopUp2}
          />
          <DemoGuidePopover
            title='Projects'
            el={() => document.getElementById('target1')}
            topOffset={-40}
            leftOffset={230}
            arrowPosition='left'
            open={popUp6}
            setOpen={setPopUp6}
            setPrev={
              activeProject?.products === 'CHECKOUT_AND_REFUND_MANAGER'
                ? setPopUp5
                : setPopUp4
            }
          >
            <div>
              <p className='text-grey-content-tertiary mt-3'>
                Let’s set up a project to configure the fraud risk algorithm and
                personalised payments experiences.
              </p>
              <button
                className='mt-2 text-white2 text-sm1 py-1 underline'
                onClick={() => {
                  navigate('/projects/manage');
                  setPopUp6(false);
                }}
              >
                Go to Projects
              </button>
            </div>
          </DemoGuidePopover>
          {navOptions1.map((item) => (
            <Link to={item.to} key={item.id}>
              <div
                role='button'
                // onClick={() => setActiveNav(item.id)}
                className={getClassNames(
                  activeNav === item.id
                    ? 'text-primary bg-active-nav/5'
                    : 'text-secondary',
                  item.to ? 'hover:text-primary' : 'cursor-default opacity-50',
                  'flex items-center justify-between w-full py-2 px-2 rounded-[5px]',
                  'font-semibold text-base1',
                )}
              >
                <span>{item.name}</span>
              </div>
            </Link>
          ))}
        </div>
        {/* <hr className="border-theme-stroke" /> */}

        <hr className='border-theme-stroke' />
        <div className='pt-5 pl-6 pr-4 flex flex-col gap-y-0.5 pb-4'>
          <span
            className='text-grey-480 w-full pb-2 px-2 block rounded-[5px] opacity-50
            font-semibold text-base1 leading-xl15'
          >
            Help & Resources
          </span>
          {navOptions2.map((item) => (
            <div
              key={item.id}
              role='button'
              // onClick={() => setActiveNav(item.id)}
              className={getClassNames(
                activeNav === item.id
                  ? 'text-primary bg-active-nav/5'
                  : 'text-grey-content-tertiary',
                'cursor-default opacity-50 flex items-center justify-between w-full',
                'py-2 px-2 rounded-[5px] font-semibold text-base1',
              )}
            >
              <span>{item.name}</span>
            </div>
          ))}
        </div>
      </div>
      <div className='px-6 py-7 bg-stroke'>{/* <OrganizationSwitch /> */}</div>
    </div>
  );
};

export default SideBar;
