import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { IRootState } from "@/redux/store";

// Utils
import { getClassNames, pecks } from "@utils/helpers";
import { CHECKOUT_AND_REFUND, SHOPPER_TRUST_INDEX } from "@utils/constants";

// Hooks
import { setProjectType } from "@redux/features/projects";
import { getProjectById } from "@redux/features/projects";
import { getShopperTrustConfig } from "@redux/features/projects";

// Components
import { Button } from "@components/button";
import DemoGuidePopover from "@components/DemoGuidePopover";
import { InputContainer } from "@components/input";
import InviteMemberModal from "./components/inviteMemberModal";
import ProjectTopBar from "./components/projectTopBar";
import RadioButton from "@components/RadioButton";
import ToolTip from "@components/ToolTip";
import UserInitial from "@components/UserInitial";

// Assets
import { ReactComponent as PaymentPersonalizationIllustration } from "@images/illustration_payment_personalization.svg";
import { ReactComponent as LockIcon } from "@icons/lock_medium.svg";
import { ReactComponent as ShopperTrustScoreIllustration } from "@images/shopper-trust-score.svg";
import { ReactComponent as RRFManageIcon } from "@icons/rrfmanager.svg";
import { ReactComponent as TickIcon } from "@icons/circle-tick-lg.svg";
import PlusIcon from "@icons/plus";
import { DemoGuidePopoverContext } from "@/context/demoGuidePopover";

const EditProject = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const options = [CHECKOUT_AND_REFUND, SHOPPER_TRUST_INDEX];
  const {
    activeProject,
    showPublishModal,
    showSaveModal,
    showPublishSuccessModal,
    showSaveSuccessModal,
  } = useSelector((state: IRootState) => state.projectSlice);

  const [usage, setUsage] = useState("Sandbox");
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [users, setUsers] = useState<string[]>([]);
  // const [showProduct, setShowProduct] = useState(false);
  const [showProduct] = useState(false);
  const [upgrade, setUpgrade] = useState(false);

  const { setPopUp9, popUp9 } = React.useContext(DemoGuidePopoverContext);

  // const addProduct = () => {
  //   setShowProduct(true);
  // };
  const onEdit = async (option: number) => {
    if (activeProject?.environment !== "SANDBOX") return;
    dispatch(getProjectById(activeProject?.id) as any);
    await dispatch(getShopperTrustConfig(activeProject?.id) as any).then(() => {
      if (upgrade) {
        dispatch(setProjectType(options[0]));
      } else {
        dispatch(setProjectType(options[option]));
      }
      if (activeProject.products === SHOPPER_TRUST_INDEX) {
        dispatch(setProjectType(SHOPPER_TRUST_INDEX));
      } else {
        dispatch(setProjectType(CHECKOUT_AND_REFUND));
    }
    navigate("/projects/configure", { replace: true, state: "Edit" });
    });
  };

  const { register } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    if (activeProject) dispatch(getProjectById(activeProject?.id) as any);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={getClassNames(
        "bg-white w-full",
        showPublishModal ||
          showSaveModal ||
          showPublishSuccessModal ||
          showSaveSuccessModal
          ? "blur-sm"
          : ""
      )}
    >
      <div className="container-solutions h-full pb-40 bg-white">
        <InviteMemberModal {...{ showInviteModal, setShowInviteModal }} />
        <ProjectTopBar
          title="Edit Project"
          showPublishButton = {false}
          backButtonAction={() => navigate('/projects/manage')}
        />
        <form className="overflow-auto h-full max-w-[850px] px-8 mx-auto mt-[5%]">
          <div className="w-full flex flex-col max-h-max h-fit gap-y-10 mb-10 text-grey-content-tertiary">
            <div>
              <input
                placeholder="Project name"
                autoFocus
                defaultValue={activeProject?.name}
                {...register("name")}
                className={getClassNames(
                  "w-full bg-white focus:outline-none focus:ring-0 text-[2rem] leading-[100%]",
                  "placeholder:text-grey-content-tertiary text-body-black"
                )}
              />
              <textarea
                placeholder="Project name"
                autoFocus
                defaultValue={activeProject?.description}
                {...register("description")}
                aria-multiline
                className={getClassNames(
                  "paragraph1 w-full mt-2 p-4 bg-white2 border border-stroke-primary rounded text-body-black",
                  "outline-none ring-0 focus:outline-none focus:ring-0"
                )}
              />

              <p className="paragraph1 mt-2">
                Give this project a name and description to define its
                objectives.
              </p>
            </div>
            <div>
              <InputContainer
                label="Collaborators"
                labelStyle="text-desc-grey"
                className={getClassNames(
                  "flex justify-between items-center bg-white4",
                  users.length ? "md:py-1" : "py-4"
                )}
              >
                {users.length ? (
                  <div className="flex gap-x-2">
                    {users.map((user) => (
                      <div
                        key={user}
                        className="flex gap-x-2 items-center rounded-full bg-stroke-primary p-2"
                      >
                        <UserInitial name={user} size="sm" color="tertiary" />
                        <span className="text-body-black">{user}</span>
                      </div>
                    ))}
                  </div>
                ) : (
                  <span>Just you</span>
                )}
                <PlusIcon
                  onClick={() => {
                    setUsers(["Samantha Jonhs", "Hugo Lee"]);
                    setShowInviteModal(true);
                  }}
                  className="cursor-pointer"
                />
              </InputContainer>
              <p className="paragraph1 mt-2">
                Invite team members from your organization to join you in
                setting up and managing this project.
              </p>
            </div>
            <div className="relative w-full">
              <div className="grid grid-cols-2 items-end gap-x-2 max-w-[695px]">
                <InputContainer
                  label="Environment"
                  labelStyle="text-desc-grey"
                  className=" flex gap-x-2 justify-between items-center cursor-pointer max-w-[334px]"
                  onClick={() => setUsage("Sandbox")}
                >
                  <div className="flex gap-x-2 items-center">
                    <div
                      className={getClassNames(
                        "w-4 h-4 rounded-full",
                        usage === "Sandbox"
                          ? "bg-trud-orange"
                          : "bg-grey-content-quaternary"
                      )}
                    />
                    <span
                      className={getClassNames(
                        "text-base1 mt-0.5",
                        usage === "Sandbox"
                          ? "text-body-black"
                          : "text-grey-content-tertiary"
                      )}
                    >
                      Sandbox
                    </span>
                  </div>
                  <RadioButton active={usage === "Sandbox"} />
                </InputContainer>
                <InputContainer
                  label=""
                  className="group flex gap-x-2 justify-between items-center max-w-[334px]"
                >
                  <div className="flex gap-x-2 items-center">
                    <div
                      className={getClassNames(
                        "w-4 h-4 rounded-full",
                        usage === "Live"
                          ? "bg-green-alert"
                          : "bg-grey-content-quaternary"
                      )}
                    />
                    <span
                      className={getClassNames(
                        "text-base1 mt-0.5",
                        usage === "Live"
                          ? "text-body-black"
                          : "text-grey-content-tertiary"
                      )}
                    >
                      Live
                    </span>
                  </div>
                  <LockIcon className="h-6" />
                  <ToolTip
                    info="Once your project is created in the Sandbox, you’ll be able to publish to the Live environment."
                    className="right-0 top-16 max-w-[231px] min-w-[231px] text-sm1"
                  />
                </InputContainer>
              </div>
              <p className="paragraph1 mt-2">
                Sandbox projects are suitable for product tests. When you're
                fully prepared for real users, <br /> seamlessly transition to
                the live environment.
              </p>
            </div>
          </div>

          <h6 className="text-grey-content-secondary header6">
            Active Products
          </h6>
          <span id="target9" className="relative bg-black h-5">
            <DemoGuidePopover
              title="Edit Project"
              body="Edit the project to move a project from Sandbox to Live."
              el={() => document.getElementById("target9")}
              topOffset={20}
              leftOffset={400}
              arrowPosition="left"
              open={popUp9}
              setOpen={setPopUp9}
            />
          </span>
          {activeProject?.products === SHOPPER_TRUST_INDEX ? (
            <div
              className={getClassNames(
                "pt-10 pb-28 px-8 rounded-lg border border-stroke-primary max-w-[367px]",
                "mt-2 relative checkout_refund_gradient cursor-pointer"
              )}
              onClick={() => onEdit(1)}
            >
              <h5 className="header5 text-body-black">Shopper Trust Index</h5>
              <p className="pr-8 mt-2 text-grey-content-tertiary">
                A fraud risk score for new and returning shoppers, to
                differentiate trusted shoppers from high-risk shoppers.
              </p>

              <Button
                title="Edit"
                color="light"
                className="mt-10"
                onClick={() => onEdit(1)}
              />
              <ShopperTrustScoreIllustration className="absolute bottom-0 right-0" />
            </div>
          ) : (
            <div
              className={getClassNames(
                "pt-10 pb-28 px-8 rounded-lg border border-stroke-primary max-w-[367px]",
                "mt-2 relative checkout_refund_gradient cursor-pointer"
              )}
              onClick={() => onEdit(1)}
            >
              <h5 className="header5 text-body-black">
                Payment Personalisation
              </h5>
              <p className="pr-8 mt-2 text-grey-content-tertiary">
                Automatically personalise routing for payments based on consumer
                fraud risk profile, at checkout and refunds.
              </p>

              <Button
                title="Edit"
                color="light"
                className="mt-10"
                onClick={() => onEdit(0)}
              />
              <PaymentPersonalizationIllustration className="absolute bottom-0 right-0" />
            </div>
          )}

          {showProduct && (
            <>
              <h6 className="text-grey-content-secondary header6 mt-6 mb-2">
                Add Products
              </h6>
              <div
                onClick={() => setUpgrade((prev) => !prev)}
                className={getClassNames(
                  "flex flex-col hover:bg-white2 max-w-[417px] cursor-pointer rounded-lg",
                  "border border-grey-stroke-primary px-8 py-10",
                  upgrade ? "bg-white2" : ""
                )}
              >
                <div className="flex flex-col flex-1 mb-6">
                  <RRFManageIcon />
                  <h4 className="header5 mt-6 mb-1">
                    Checkout and refund manager
                  </h4>
                  <p className="text-grey-content-tertiary text-base1 leading-xl1">
                    Personalised experiences for shoppers based on their unique
                    risk profiles to eliminate fraud and improve experience.
                  </p>
                  <h6 className="text-base text-grey-content-tertiary mt-8">
                    Includes
                  </h6>
                  {pecks.CRM.map((peck) => (
                    <div
                      key={peck}
                      className="flex items-center gap-x-2 px-4 py-3 border border-grey-stroke-primary"
                    >
                      <div className="w-2 h-2 bg-grey-stroke-primary" />
                      <h6 className="text-base text-body-black">{peck}</h6>
                    </div>
                  ))}
                </div>
                <Button
                  title={upgrade ? "Selected" : "Select"}
                  category="2"
                  color="light"
                  className="w-fit transition-all duration-500"
                  prefixIcon={
                    upgrade ? (
                      <TickIcon className="w-3 mr-0.5" />
                    ) : (
                      <PlusIcon className="mr-0.5" />
                    )
                  }
                />
              </div>
            </>
          )}

          {activeProject?.products === SHOPPER_TRUST_INDEX && !showProduct && (
            <Button
              title="Add project"
              prefixIcon={<PlusIcon />}
              category="2"
              color="dark"
              className={getClassNames(
                "mt-8 w-full mx-auto py-3",
                "text-body-black bg-white4 border border-primary"
              )}
              titleStyle="text-body-black ml-1"
              onClick={() => {}}
            />
          )}

          {showProduct && (
            <Button
              title="Continue"
              category="1"
              color="dark"
              className={getClassNames(
                "mx-auto w-full max-w-[383px] mt-10 py-3"
              )}
              onClick={() => onEdit(0)}
            />
          )}
        </form>
      </div>
    </div>
  );
};

export default EditProject;
