import React, { useState, useRef, useEffect } from "react";

// Assets
import { ReactComponent as SearchIcon } from "@icons/search.svg";
import { ReactComponent as CancelIcon } from "@icons/cancel.svg";

interface SearchInputProps {
  placeholder?: string;
  handleChange: (query: string) => void;
  queryParam?: string;
  className?: string;
}

const SearchInput = ({
  placeholder,
  handleChange,
  queryParam,
  className="rounded-[20px] h-[37px]",
}: SearchInputProps) => {
  const [query, setQuery] = useState("");
  const inputRef = useRef<HTMLInputElement>(null); // Initialize inputRef with the correct type
  const [startSearch, setStartSearch] = useState(false);
  const handleQueryChange = (query: string) => {
    handleChange(query);
    setQuery(query);
  };
  const clearSearch = () => {
    inputRef.current!.value = ""; // Use non-null assertion operator to access value property
    handleQueryChange("");
  };

  useEffect(() => {
    if (queryParam?.length === 0) clearSearch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParam]);

  return (
    <div
      className={`flex items-center border border-grey-400 px-4  gap-x-2 ${className} ${
        startSearch ? "bg-white" : "bg-body-variant"
      }`}
    >
      <SearchIcon />
      <input
        type="text"
        placeholder={placeholder || "Search"}
        onFocus={() => setStartSearch(true)}
        onBlur={() => setStartSearch(false)}
        onChange={(e) => handleQueryChange(e.target.value)}
        className={`text-base border-0 outline-none focus:border-0 ring-0 w-full ${
          startSearch ? "bg-white" : "bg-body-variant"
        }`}
        ref={inputRef}
      />
      {query?.length > 0 && (
        <CancelIcon className="cursor-pointer" onClick={clearSearch} />
      )}
    </div>
  );
};

export default SearchInput;
