import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { getClassNames } from "@utils/helpers";

// Components
import { Button } from "@components/button";
import Input, { InputContainer } from "@components/input";
import Switch from "@components/switch";

const ServerConfigForm = ({ onContinue }) => {
  const [enableServerAsProvider, setEnableServerAsProvider] = useState(false);

  const {
    register,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });
  return (
    <form className="mt-6 flex flex-col gap-y-6">
      <Input
        label="FQDN"
        name="FQDN"
        category="regular"
        {...{ register }}
        rules={{
          required: true,
        }}
        className="flex-1 text-lg"
      />
      <Input
        label="Password"
        name="password"
        type="password"
        category="regular"
        {...{ register }}
        rules={{
          required: true,
        }}
        className="flex-1 text-lg"
      />
      <Input
        label="Confirm Password"
        name="cpassword"
        type="password"
        category="regular"
        {...{ register }}
        rules={{
          required: true,
        }}
        className="flex-1 text-lg"
      />
      <InputContainer
        label="Enable LADP server as the provider server"
        className="w-[50%]"
      >
        <div
          className={getClassNames("flex items-center justify-between gap-x-2")}
        >
          <span
            className={getClassNames(
              "block truncate text-lg leading-5 font-semibold",
              "text-body-black"
            )}
          >
            Active
          </span>
          <Switch
            checked={enableServerAsProvider}
            toggleCheck={() =>
              setEnableServerAsProvider(!enableServerAsProvider)
            }
          />
        </div>
      </InputContainer>
      <Button
        title="Continue"
        color="dark"
        category="1"
        className="w-fit"
        onClick={() => onContinue()}
      />
    </form>
  );
};

export default ServerConfigForm;
