import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Utils
import { getClassNames, getLocalStorageData } from '@utils/helpers';
import { SHOPPER_TRUST_INDEX } from '@utils/constants';

// Context
import { DemoGuidePopoverContext } from '@context/demoGuidePopover';

// Redux
import { publishModalHandler } from '@redux/features/projects';
import { IRootState } from '@redux/store';

// Assets
import { ReactComponent as CaretIcon } from '@icons/arrow_down.svg';
import FocusedStateCheckoutAndRefund from './focusedState';
import { authContext } from '@/context/auth';

type ConfigurationNavProps = {
  step: number;
  setStep: (step: number) => void;
  stage: number;
  setStage: (stage: number) => void;
};

const ConfigurationNav: React.FC<ConfigurationNavProps> = ({
  step,
  setStep,
  stage,
  setStage,
}) => {
  const dispatch = useDispatch();
  const { projectType } = useSelector((state: IRootState) => state.projectSlice);
  const [showFocusedStated, setShowFocusedStated] = React.useState<boolean>(true);
  const { setPopUp17, popUp17, popUp19, setPopUp19, setPopUp23, setPopUp18, setPopUp20 } =
    useContext(DemoGuidePopoverContext);

  const { isAuthenticated } = useContext(authContext);

  const isPopUpDisable = getLocalStorageData('popUpDisabled');
  const steps =
    projectType === SHOPPER_TRUST_INDEX
      ? [
          {
            title: 'Shopper Trust Index',
            stages: [
              'Select a fraud risk type',
              'Customise Shopper Trust Index',
              'Define Trusted Shopper scores',
            ],
          },
          {
            title: 'Publish',
            stages: ['Generate API key', 'Publish project'],
          },
        ]
      : [
          {
            title: 'Shopper Trust Index',
            stages: [
              'Select a fraud risk type',
              'Customise Shopper Trust Index Algorithm',
              'Define Trusted Shopper scores',
            ],
          },
          {
            title: 'Payment Personalisation',
            stages: ['Define shopper Experience'],
          },
          {
            title: 'Publish',
            stages: ['Generate API key', 'Publish project'],
          },
          {
            title: 'Publish',
            stages: ['Generate API key', 'Publish project'],
          },
          {
            title: 'Setup Open Banking',
            stages: ['Consent', 'Authenticate', 'Authorize'],
          },
        ];
  return (
    <div
      className={getClassNames(
        'flex flex-col gap-y-4 h-full bg-body-variant2 text-grey-content-secondary py-8 pl-8 pr-4',
        'max-h-full overflow-auto'
      )}
      id="targetNav1"
    >
      {steps.slice(0, projectType === 'checkoutAndRefund' ? 4 : 3).map((option, i) => (
        <div key={option.title}>
          <div className="flex items-center justify-between">
            <span className="">Step {i + 1}</span>
            <CaretIcon />
          </div>
          <div
            key={option.title}
            className={getClassNames(
              'relative flex flex-col gap-y-1 text-sm1 pb-10 mt-2 pl-3 border-l border-grey-stroke-primary'
            )}
          >
            <span className="">{option.title}</span>
            <div className="absolute min-w-[6px] min-h-[6px] w-[6px] h-[6px] rounded-full top-0 -left-[0.215rem] bg-grey-stroke-primary" />
            {option.stages.map((stageTitle, j) => (
              <div className="relative" key={stageTitle}>
                <div
                  className={getClassNames(
                    'absolute min-w-[6px] min-h-[6px] w-[6px] h-[6px] rounded-sm top-[50%] -left-4 bg-grey-content-secondary',
                    step === i && stage === j ? 'block' : 'hidden'
                  )}
                />

                <h6
                  className={getClassNames(
                    'hover:bg-grey-stroke-primary hover:text-black rounded px-3 py-2',
                    'w-full mb-0.5 cursor-pointer',
                    step === i && stage === j ? 'text-black bg-grey-stroke-primary' : ''
                  )}
                  onClick={() => {
                    if (i < 2 && !(projectType === SHOPPER_TRUST_INDEX && i == 1)) {
                      setStep(i);
                      setStage(j);
                    } else {
                      dispatch(publishModalHandler(true));
                    }
                  }}
                >
                  {stageTitle}
                </h6>
              </div>
            ))}
          </div>
        </div>
      ))}

      {!(isAuthenticated && isPopUpDisable) && (
        <FocusedStateCheckoutAndRefund
          {...{ showFocusedStated }}
          onClick={() => {
            setShowFocusedStated(false);
            setPopUp23(true);
          }}
        >
          <h6 className="text-white">The Shopper Trust Index</h6>
          <p className="text-sm1 text-grey-content-tertiary mt-3">
            This is a fraud risk score that helps you differentiate Trusted shoppers from
            High risk shoppers. <br /> <br /> Personalised experiences like instant
            refunds are executed based on the Shopper Trust Index.
          </p>
        </FocusedStateCheckoutAndRefund>
      )}

      <FocusedStateCheckoutAndRefund
        showFocusedStated={popUp17 && stage === 2}
        onClick={() => {
          setPopUp17(false);
          setPopUp18(true);
        }}
      >
        <h6 className="text-white">Step 2. Segment consumers</h6>
        <p className="text-sm1 text-grey-content-tertiary mt-3">
          Define thresholds for trusted, medium risk and high risk shoppers to segment
          consumers
          <br />
          <br />
          Our ML algorithm will recommend optimal thresholds for your organisation
        </p>
      </FocusedStateCheckoutAndRefund>
      <FocusedStateCheckoutAndRefund
        showFocusedStated={popUp19 && step === 1}
        onClick={() => {
          setPopUp19(false);
          setPopUp20(true);
        }}
      >
        <h6 className="text-white">Step 3. Personalise consumer experiences</h6>
        <p className="text-sm1 text-grey-content-tertiary mt-3">
          Now, you’re ready to define the personalised experiences for consumers based on
          consumer fraud risk, at checkout and refunds
        </p>
      </FocusedStateCheckoutAndRefund>
    </div>
  );
};

export default ConfigurationNav;
