import React from "react";
import { useSelector } from "react-redux"
import { IRootState } from "@/redux/store";

import { getClassNames } from "@utils/helpers";

type StepsIndicatorProps = {
  step: number;
  stepsData: any;
  stage: number;
};

const StepsIndicator: React.FC<StepsIndicatorProps> = ({ step, stepsData, stage }) => {
  const { shopperTrustTotal, trustedShopperScore } = useSelector((state: IRootState) => state.projectSlice)
  const totals = [shopperTrustTotal, shopperTrustTotal, trustedShopperScore]
  return (
    stepsData[step] && stepsData[step][stage] &&
    <>
      <div className="flex items-center gap-x-4">
        <div className="text-grey-content-secondary rounded-3xl bg-grey-stroke-primary px-2 py-0.5">
          Step {step + 1}
        </div>
        <span className="text-lg">{stepsData[step][stage]["stepTitle"] ?? stepsData[step][stage]["title"]}</span>
      </div>
      <div className="flex justify-between mb-11">
        <div className="max-w-[631px]">
          <h2 className="header2 mt-6 text-trud-black">
            {stepsData[step][stage]["title"]}
          </h2>
          <p className="text-base1 leading-xl1 mt-2">
            {stepsData[step][stage]["description"]}
          </p>
        </div>
        {step <= 1 && (
          <div className="flex flex-col items-center">
            <div
              className={getClassNames(
                "py-4 px-6 rounded-full bg-grey text-[3.5rem] flex items-center justify-center w-[110px] h-[110px]",
                (stage === 1 && shopperTrustTotal !== 100) ||
                  (stage === 2 && trustedShopperScore < 80)
                  ? "text-red-alert"
                  : "text-score-green"
              )}
            >
              {step === 1 ? trustedShopperScore : totals[stage]}
            </div>
            {stage > 1 && (
              <span className={getClassNames("text-sm1")}>or more</span>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default StepsIndicator;
