import ReactListbox from '@/components/FormElement/ReactListbox';
import Table from '@/components/Table/Table';
import SearchInput from '@/components/searchInput';
import InfrastructureModal from '@/pages/dashboard/components/InfrastructureModal';
import { DropDownOptions } from '@/utils/constants';
import { ReactComponent as Chatline1 } from '@images/chatlines1.svg';
import { useState } from 'react';
import InfrastructureModalTransaction from './InfrastructureModalTransaction';

type InfraStuctureDataProps = {
  hash: string;
  blockNo: string;
  method: string;
  totalTransactions: string;
  timestamp: string;
  status: string;
};

const tableData = [
  {
    hash: '0xe104db60e0267dadf3c97fd725b88e199f2f5f3e9ba',
    blockNo: '10641103',
    method: 'Consumer Trust Index calculation',
    totalTransactions: '29',
    timestamp: '15 sec ago',
    status: 'Successful',
  },
  {
    hash: '0xe104db60e0267dadf3c97fd725b88e199f2f5f3e9ba',
    blockNo: '10641102',
    method: 'Consumer Trust Index calculation',
    totalTransactions: '17',
    timestamp: '15 sec ago',
    status: 'Successful',
  },
  {
    hash: '0xe104db60e0267dadf3c97fd725b88e199f2f5f3e9ba',
    blockNo: '10641101',
    method: 'Consumer Trust Index calculation',
    totalTransactions: '35',
    timestamp: '15 sec ago',
    status: 'Successful',
  },
  {
    hash: '0xe104db60e0267dadf3c97fd725b88e199f2f5f3e9ba',
    blockNo: '10641100',
    method: 'Consumer Trust Index calculation',
    totalTransactions: '33',
    timestamp: '15 sec ago',
    status: 'Successful',
  },
  {
    hash: '0xe104db60e0267dadf3c97fd725b88e199f2f5f3e9ba',
    blockNo: '10641099',
    method: 'Consumer Trust Index calculation',
    totalTransactions: '22',
    timestamp: '15 sec ago',
    status: 'Successful',
  },
  {
    hash: '0xe104db60e0267dadf3c97fd725b88e199f2f5f3e9ba',
    blockNo: '10641098',
    method: 'Consumer Trust Index calculation',
    totalTransactions: '24',
    timestamp: '1 min ago',
    status: 'Successful',
  },
  {
    hash: '0xe104db60e0267dadf3c97fd725b88e199f2f5f3e9ba',
    blockNo: '10641098',
    method: 'Consumer Trust Index calculation',
    totalTransactions: '24',
    timestamp: '10 min ago',
    status: 'Declined',
  },
  {
    hash: '0xe104db60e0267dadf3c97fd725b88e199f2f5f3e9ba',
    blockNo: '10641098',
    method: 'Consumer Trust Index calculation',
    totalTransactions: '24',
    timestamp: '10 min ago',
    status: 'Successful',
  },
  {
    hash: '0xe104db60e0267dadf3c97fd725b88e199f2f5f3e9ba',
    blockNo: '10641098',
    method: 'Consumer Trust Index calculation',
    totalTransactions: '24',
    timestamp: '12 min ago',
    status: 'Successful',
  },
  {
    hash: '0xe104db60e0267dadf3c97fd725b88e199f2f5f3e9ba',
    blockNo: '10641098',
    method: 'Consumer Trust Index calculation',
    totalTransactions: '24',
    timestamp: '6/23 3:52',
    status: 'Successful',
  },
];

const PartnerInfraStructure = () => {
  const [range, setRange] = useState<number>(30);
  const [blockData, setBlockData] = useState<InfraStuctureDataProps>(tableData[0]);
  const [infrastructureModal, setInfrastructureModal] = useState(false);
  const [infrastructureTransactionModal, setInfrastructureTransactionModal] =
    useState(false);
  return (
    <div className="mb-8">
      <InfrastructureModal
        closeModal={setInfrastructureModal}
        isOpen={infrastructureModal}
        displayData={tableData}
        blockData={blockData}
      />
      <InfrastructureModalTransaction
        transactionData={blockData}
        isOpen={infrastructureTransactionModal}
        closeModal={setInfrastructureTransactionModal}
      />
      <div className="flex items-center justify-between mb-2">
        <p className="text-grey-content-secondary text-base leading-5 font-semibold">
          Blockchain activity
        </p>
        <ReactListbox
          selectedOption={range}
          onChange={setRange}
          Options={DropDownOptions.DateRangeOptions}
        />
      </div>
      <div className="border border-solid border-stroke-primary rounded-lg overflow-hidden">
        <SearchInput
          handleChange={() => {}}
          placeholder="Search by address, hash or block"
          className="border-0 py-4"
        />
        <div className="flex w-full">
          <div className="relative w-2/4 border-r border-solid border-stroke-primary">
            <div className="relative flex justify-between items-center p-4 bg-white2 border border-solid border-stroke-primary border-l-0 border-r-0">
              <div className="relative">
                <p className="text-base font-semibold leading-5 text-grey-content-secondary mb-1">
                  Blocks
                </p>
                <h3 className="text-xl2 font-semibold leading-2xl2 text-black mb-1">
                  157
                </h3>
                <span className="text-score-green font-semibold text-sm leading-sm">
                  +20%
                </span>
              </div>
              <div className="relative">
                <Chatline1 />
              </div>
            </div>
            <div className="flex items-center justify-between p-4 border-b-[1px] border-stroke">
              <h6 className="text-sm1 font-semibold leading-lg text-body-black">
                Latest blocks
              </h6>
            </div>
            <Table
              className="!px-0 border-x-0 border-y-0 !mx-0 !mb-0"
              bodyData={tableData}
              headerData={[
                {
                  header: '',
                  option: { isIndex: true },
                  className: 'w-1 text-center',
                },
                {
                  cell: (props) => {
                    return (
                      <div
                        className="flex items-center justify-between font-semibold"
                        onClick={() => {
                          setInfrastructureModal(true);
                          setBlockData(props as InfraStuctureDataProps);
                        }}
                      >
                        <div>
                          Block{' '}
                          <span className="text-body-black mr-2">{props.blockNo}</span>
                        </div>
                        <div>{props.totalTransactions} Transactions</div>
                        <div>{props.timestamp}</div>
                      </div>
                    );
                  },
                  header: '',
                },
              ]}
              totalPage={0}
            />
          </div>
          <div className="relative w-2/4">
            <div className="relative flex justify-between items-center p-4 bg-white2 border border-solid border-stroke-primary border-l-0 border-r-0">
              <div className="relative">
                <p className="text-base font-semibold leading-5 text-grey-content-secondary mb-1">
                  Transactions
                </p>
                <h3 className="text-xl2 font-semibold leading-2xl2 text-black mb-1">
                  202
                </h3>
                <span className="text-score-green font-semibold text-sm leading-sm">
                  +20%
                </span>
              </div>
              <div className="relative">
                <Chatline1 />
              </div>
            </div>
            <div className="flex items-center justify-between p-4 border-b-[1px] border-stroke">
              <h6 className="text-sm1 font-semibold leading-lg text-body-black">
                Latest transactions
              </h6>
            </div>
            <Table
              className="!px-0 border-x-0 border-y-0 !mx-0 !mb-0"
              bodyData={tableData}
              headerData={[
                {
                  cell: (props) => {
                    return (
                      <div
                        className="flex items-center justify-between font-semibold"
                        onClick={() => {
                          setInfrastructureTransactionModal(true);
                          setBlockData(props as InfraStuctureDataProps);
                        }}
                      >
                        <div className="flex items-center">
                          <div
                            className={`rounded-full ${props.status === 'Successful' ? 'bg-green-alert' : props.status === 'Pending' ? 'bg-trud-orange' : 'bg-red-alert'} h-2 w-2 mr-3`}
                          />
                          <div className="text-body-black">
                            {props.hash.substring(0, 20) + '...'}
                          </div>
                        </div>
                        <div>
                          Block{' '}
                          <span className="text-body-black mr-2">{props.blockNo}</span>
                        </div>
                        <div>{props.timestamp}</div>
                      </div>
                    );
                  },
                  header: '',
                },
              ]}
              totalPage={0}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerInfraStructure;
